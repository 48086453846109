import React from "react";
import { motion } from "framer-motion"

interface CartProps { cartItems: number }

export class CartButton extends React.Component<CartProps, CartProps> {
    state = {
        cartItems: 0
    }

    componentDidMount() {
        this.setState({ cartItems: this.props.cartItems })
    }

    componentDidUpdate() {
        if (this.props.cartItems !== this.state.cartItems) {
            this.setState({ cartItems: this.props.cartItems })
        }
    }

    render() {
        return <div
            className='navbarButtonSpecial'
            style={{
                overflow: "hidden",
                fontWeight: 'bold', display: "flex", flexDirection: "row"
            }} >

            <div style={{ textAlign: 'left', marginRight: 10 }}>
                <i className='fas fa-shopping-cart'></i>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                <motion.div animate={{ y: 0, scale: [1, 1.5, 1.5, 1, 1] }}
                    style={{ flex: 0 }}>
                    <span >{this.state.cartItems}</span>
                </motion.div>
            </div>

        </div>
    }
}
