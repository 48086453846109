import React, { Component } from 'react';
// import { Options } from '../input/options';
import {
  SFPPaintPrices,
  SFPPaintInfo,
  SFPPaint
} from '../../shared/interfaces';
import { api } from '../../../api';

interface Props {
  product: { car: { brandName: string, modelName: string }, paint: SFPPaint };
  updateCart: () => void;
  onClose: () => void
}

interface State {
  selection: any;
  cartButtonText: string;
  prices: SFPPaintPrices;
  paintinfo: SFPPaintInfo;
}

export class PaintProduct extends Component<Props, State> {
  state = {
    cartButtonText: 'ADD TO CART',
    selection: 'basic',
    prices: { basic: 0.0, advanced: 0.0 },
    paintinfo: { basic: [], advanced: [] }
  };

  componentDidMount = () => {
    api.getPrices((err, prices) => {
      if (err) {
        console.error(err.toString());
      }
      this.setState({ prices });
    });

    api.getProductInfo((err, paintinfo: SFPPaintInfo) => {
      if (err) {
        console.error(err.toString());
      }
      this.setState({ paintinfo });
    });
  };

  addToCart = (selection: string) => {
    const product = {
      car: this.props.product.car,
      paint: this.props.product.paint,
      package: selection
    };

    fetch('/api/cart/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(product)
    }).then(() => {
      this.setState({ cartButtonText: 'ADDED' });
      this.props.updateCart();

      setTimeout(() => {
        this.setState({ cartButtonText: 'ADD TO CART' });
        this.props.onClose();
      }, 500);
    });
  };

  optionsVariable = (selection: string) => {
    if (!this.state.selection) {
      return <div></div>;
    }

    const pricebasic = this.state.prices.basic
      ? this.state.prices.basic.toFixed(2)
      : '';
    const priceadvanced = this.state.prices.advanced
      ? this.state.prices.advanced.toFixed(2)
      : '';

    if (selection === 'basic') {
      return (
        <div style={{
          paddingTop: 0, paddingBottom: 0, height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>


          <div style={{ flex: 1 }}>
            {this.state.paintinfo.basic.map(info => {
              return (
                <div style={{ fontSize: 12, margin: 0, padding: 0, lineHeight: 1.5 }} key={info}>
                  {info}
                </div>
              );
            })}
          </div>
          <h2 className='price' style={{ float: 'none', fontSize: 15, margin: 0, paddingTop: 5, paddingBottom: 5 }}>R {pricebasic}</h2>
        </div>
      );
    }
    if (selection === 'advanced') {
      return (
        <div style={{ paddingTop: 0, paddingBottom: 0 }}>



          {this.state.paintinfo.advanced.map(info => {
            return (
              <div style={{ fontSize: 12, margin: 0, padding: 0, lineHeight: 1.5 }} key={info}>
                {info}
              </div>
            );
          })}
          <h2 className='price' style={{ float: 'none', fontSize: 15, margin: 0, paddingTop: 5, paddingBottom: 5 }}>R {priceadvanced}</h2>
        </div>
      );
    }
  };

  render() {
    if (!this.props.product) {
      return null;
    }


    return (
      <div style={{
        width: "100%",
        position: 'fixed',
        top: 70,
        zIndex: 100,
        paddingLeft: 10,
        paddingRight: 10
      }}>
        <div className='row container'
          style={{
            zIndex: 5,
            margin: "10px auto",
            border: '1px solid rgba(0,0,0,0.1)',
            borderTopRightRadius: 10,
            background: 'white',
            padding: 0,
            paddingBottom: 10,
            position: 'relative'
          }}>

          <div style={{
            background: "rgb(29,71,130)",
            padding: "8px 15px",
            color: "#ffffff",
            position: 'absolute',
            top: 0, right: 0,
            borderBottomLeftRadius: 10,
            borderTopRightRadius: 10
          }}
            onClick={this.props.onClose}
          >
            <i className="fa fa-close" />
          </div>



          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingTop: 10,
            paddingLeft: 10
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <div style={{
                border: '2px solid rgba(0,0,0,0.1)',
                background: this.props.product.paint.color,
                width: 65,
                height: 65,
                borderRadius: 5,
                marginRight: 10
              }} />
              <div className='paintProductColorSwatchDesc'>
                {this.props.product.car.brandName}{' '}
                {this.props.product.car.modelName}{' '}
                <br />
                {this.props.product.paint.desc}<br />
                <div className='paintProductColorSwatchCode'>
                  {this.props.product.paint.code}
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingLeft: 10, paddingRight: 10, margin: 0 }}>
            <p style={{ padding: 0, margin: 0 }}>
              Perfectly mixed DIY touch-up paint kits for scratch and chip
              repairs on your car for factory matching colour fixes.
            </p>
          </div>

          <div style={{ paddingLeft: 10, paddingRight: 10, width: '100%' }}>
            <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 0 }}>
              <div style={{
                flex: 1, width: '50%',
                border: '1px solid rgba(0,0,0,0.1)',
                background: 'rgba(0,0,0,0.025)',
                marginRight: 10,
                padding: 10,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div><span style={{ color: '#405DA0', fontWeight: 'bold' }}>BASIC</span></div>

                <div style={{ flex: 1 }}>{this.optionsVariable("basic")}</div>

                <div  >
                  <button
                    className='blueButton'
                    style={{ width: '100%' }}
                    onClick={() => this.addToCart("basic")}
                    disabled={!this.state.selection}
                  >
                    <i className='fas fa-cart-plus'></i> {this.state.cartButtonText}
                  </button>
                </div>

              </div>
              <div style={{
                flex: 1, width: '50%',
                border: '1px solid rgba(0,0,0,0.1)',
                background: 'rgba(0,0,0,0.025)',
                padding: 10
              }}>
                <span style={{ color: '#405DA0', fontWeight: 'bold' }}>ADVANCED</span>

                {this.optionsVariable("advanced")}

                <div>
                  <button
                    className='blueButton'
                    style={{ width: '100%' }}
                    onClick={() => this.addToCart("advanced")}
                    disabled={!this.state.selection}
                  >
                    <i className='fas fa-cart-plus'></i> {this.state.cartButtonText}
                  </button>
                </div>

              </div>
            </div>


          </div>
        </div>

      </div>
    );
  }
}
