import styled from "styled-components";

export const SmallTitle = (props: { children: any }) => {
    return <div style={{
        paddingTop: 10, paddingBottom: 5,
        fontSize: 10, color: "gray"
    }}>{props.children}<br /></div>
}

export const SmallTextButton = styled.a`
    cursor: pointer;
`;