import { Component } from "react";
import { SFPPaint } from "../../shared/interfaces";
import { rgb2hsv } from "../../shared/tools";

interface Props {
  paints: any,
  codeFilter: string,
  selectPaint: (paint: SFPPaint) => any
}
interface State { }

export class PaintBrowser extends Component<Props, State> {
  state = {};

  // parse RGB css linear gradient
  parseRGB = (col: SFPPaint) => {
    try {
      let x = col.color.split("to right,")[1].split(", rgb(")[0].trim().split('rgb(').join("").split(")").join("").split(",");
      return {
        r: parseInt(x[0]),
        g: parseInt(x[1]),
        b: parseInt(x[2])
      }
    } catch (err) {
      console.log(col);
      throw err;
    }

  }

  sortByHue = (a: SFPPaint, b: SFPPaint) => {
    let a_rgb = this.parseRGB(a);
    let b_rgb = this.parseRGB(b);
    let a_hsv = rgb2hsv(a_rgb.r, a_rgb.g, a_rgb.b)
    let b_hsv = rgb2hsv(b_rgb.r, b_rgb.g, b_rgb.b)


    let a_h = (a_hsv.h + 270) % 360;
    let b_h = (b_hsv.h + 270) % 360;


    return (a_h <= b_h) ? 1 : -1
  }

  render() {
    if (!this.props.paints) {
      return null;
    }

    if (this.props.paints) {
      let paints = this.props.paints.sort(this.sortByHue);

      if (this.props.codeFilter !== "") {
        paints = paints.filter(
          (paint: SFPPaint) =>
          ((paint.code.toLowerCase().indexOf(this.props.codeFilter.toLowerCase()) >= 0) ||
            (paint.desc.toLowerCase().indexOf(this.props.codeFilter.toLowerCase()) >= 0))
        )
      }

      return (
        <div
          className="container"
          style={{
            paddingTop: 10,
            paddingBottom: 15,
            background: "rgb(245,245,245)"
          }}
        >
          {(this.props.codeFilter !== "") && <span>
            Filter <strong>{this.props.codeFilter.toUpperCase()}</strong> matched {paints.length} paints out of {this.props.paints.length}.
          </span>}
          <div
            className="row"
            style={{ padding: 0, marginLeft: 0, marginRight: 0 }}
          >
            {paints.map((paint: SFPPaint, i: string) => {
              return <Swatch key={i} paint={paint} onClick={this.props.selectPaint(paint)} />;
            })}
          </div>
        </div>
      );
    }
  }
}


function Swatch(props: { paint: SFPPaint, onClick: any }) {
  return <div
    className="col-4 col-lg-2 colorSwatchesCol"
    onClick={props.onClick}
  >
    <div className="colorSwatchesBlocks">
      <div
        className="colorSwatchRounded"
        style={{ background: props.paint.color }}
      />
      <div className="colorSwatchDesc">{props.paint.desc}</div>
      <div className="colorSwatchCode">{props.paint.code}</div>
    </div>
  </div>
}