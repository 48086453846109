import React, { Component } from "react";
import { CartItemNewPaint, CartItemOldPaint, CartItemProduct, Order } from "../../../../../shared/interfaces"

import { Graph, processDataCumulative, processDataPerformance } from "./orderGraph";

import styled from 'styled-components'
import { SButton } from "../button";
import { SmallTextButton, SmallTitle } from "../../paper/components";
import { Box, Button, CircularProgress } from "@mui/material";

const STable = styled.table`

    background : none;
    font-size: 12px;

    thead {
        tr {
            td {
                white-space: nowrap;
            }
        }
    }

    tbody {
        
        tr {
            background : rgb(245,245,245);
            
            td {
                border: 1px solid rgb(235,235,235);
                border-bottom: 10px solid rgb(255,255,255);
                padding: 10px;
                background : none;
                margin-bottom: 5;
                vertical-align: top;
            }
        }
    }
`;

interface Props { }
interface State {
    orders: Order[],
    graphdata: any,
    loading: boolean,
    showDone: boolean
    sortBy?: 'orderDate' | 'numberOfOrdersMade'

    graphType: 'cumulative' | 'performance'
}



export class Orders extends Component<Props, State>  {
    state: State = {
        orders: [],
        graphdata: {
            min: 0,
            max: 100,
            data: [
                {
                    key: "lineA", data: [
                        { key: new Date('11/29/2019'), data: 15 },
                        { key: new Date('11/30/2019'), data: 13 },
                        { key: new Date('12/1/2019'), data: 26 }]
                },
                {
                    key: "lineB", data: [
                        { key: new Date('11/29/2019'), data: 11 },
                        { key: new Date('11/30/2019'), data: 12 },
                        { key: new Date('12/1/2019'), data: 29 }]
                }
            ]
        },
        loading: true,
        showDone: false,
        graphType: 'performance'
    };

    componentDidMount() {
        this.getOrders();
    }

    getOrders = () => {
        fetch("/api/admin/orders", { method: "GET" })
            .then(response => response.json())
            .then((orders: Order[]) => {

                orders = orders.map(order => {

                    // count amount of orders this person has made
                    order.numberOfOrdersMade = orders.filter(o => o.user.info.email === order.user.info.email).length

                    return order;
                })

                this.setState({
                    orders,
                    loading: false
                });
            })
            .catch(err => console.error(err.toString()));
    }

    graph = () => {

        if (!this.state.orders) return <CircularProgress />



        return (
            <Box sx={{ m: 2, p: 2, height: 550, background: "#1a2a4e", pl: 5, pr: 5 }}>
                <Button variant="outlined"
                    color="secondary"
                    onClick={() => {
                        this.setState({ graphType: 'cumulative' })
                    }}
                    sx={{}}>total turnover</Button>

                <Button variant="outlined"
                    color="secondary"
                    onClick={() => {
                        this.setState({ graphType: 'performance' })
                    }}
                    sx={{ ml: 2 }}>monthly</Button>

                {this.state.graphType === 'cumulative' && <Graph data={processDataCumulative(this.state.orders).data} />}
                {this.state.graphType === 'performance' && <Graph data={processDataPerformance(this.state.orders).data} />}


            </Box>
        )
    }

    sortOrders = (a: Order, b: Order) => {

        if (this.state.sortBy !== undefined) {
            if (this.state.sortBy === 'numberOfOrdersMade') {
                if ((a.numberOfOrdersMade || 1) > (b.numberOfOrdersMade || 1)) { return -1 } else { return 1 }
            }
        }

        return 1;
    }

    render() {

        // var chartdataCumulative: any = undefined;

        // if (this.state.orders) {
        //     var calc = processData(this.state.orders);
        //     chartdataCumulative = {
        //         data: [{ key: new Date('11/29/2019'), data: 15 }, { key: new Date('11/30/2019'), data: 13 }, { key: new Date('12/1/2019'), data: 26 }],
        //         min: calc.min,
        //         max: calc.max
        //     }
        // }

        return (
            <div>
                <div>
                    {this.graph()}
                </div>

                <div className="container">
                    <div className="row" style={{ paddingTop: 50 }}>
                        <div className="col" style={{ paddingTop: 15 }}>
                            <h3>ORDERS</h3>
                            <p>Please note: latest is now at top</p>
                        </div>
                    </div>

                    <STable style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <td>
                                    <SmallTitle>Sort By:</SmallTitle>
                                    <SmallTextButton onClick={() => {
                                        this.setState({ sortBy: 'orderDate' });
                                    }}>orderDate</SmallTextButton>
                                    &nbsp;|
                                    &nbsp;<SmallTextButton onClick={() => {
                                        this.setState({ sortBy: 'numberOfOrdersMade' });
                                    }}>numberOfOrdersMade</SmallTextButton>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{
                                    width: 100,
                                    textAlign: "right",
                                    padding: 10
                                }}>Show done
                                    <button style={{
                                        width: 30,
                                        background: '#fff',
                                        color: 'gray',
                                        border: 'none',
                                        outline: 'none'
                                    }} onClick={() => {
                                        this.setState({ showDone: !this.state.showDone })
                                    }}>{(this.state.showDone)
                                        ? <i className="fas fa-eye" />
                                        : <i className="fas fa-eye-slash" />}
                                    </button>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orders.reverse().sort(this.sortOrders).filter((order: Order) => {

                                if (this.state.showDone) {
                                    return order;
                                } else {
                                    if (!order.done) {
                                        return order;
                                    } else return null;
                                }

                            }).map((order: Order) => {

                                if (!order) return null;
                                return <tr className="order" key={order._id}>
                                    <td>
                                        <div style={{
                                            paddingBottom: 5, fontSize: 10,
                                            color: "gray"
                                        }}>Order date:<br /></div>
                                        {order.created.jsonTime}<br />
                                        <div style={{
                                            paddingTop: 10, paddingBottom: 5,
                                            fontSize: 10, color: "gray"
                                        }}>Contact:<br /></div>
                                        {order.user.info.firstName} {order.user.info.lastName}<br />
                                        {order.user.info.email}<br />
                                        {order.user.info.telephone}<br /><br />
                                        <SmallTitle>Times this customer has ordered:</SmallTitle>
                                        {order.numberOfOrdersMade}

                                    </td>
                                    <td>
                                        <div style={{
                                            paddingBottom: 5, fontSize: 10,
                                            color: "gray"
                                        }}>Ship to:<br /></div>
                                        {order.user.info.address1}<br />
                                        {order.user.info.address2}<br />
                                        {order.user.info.address3}<br />
                                        {order.user.info.postcode}<br />
                                        <div style={{
                                            paddingTop: 10, paddingBottom: 5, fontSize: 10,
                                            color: "gray"
                                        }}>Waybill:<br /></div>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            height: 36
                                        }}>
                                            <input style={{
                                                padding: "3px 10px", flex: 1, height: 36,
                                                margin: 0,
                                                borderRadius: 3,
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                                border: '1px solid gray'
                                            }} type="text" onChange={async (e) => {
                                                order.waybill = e.target.value;
                                                this.setState({ orders: this.state.orders }, () => {
                                                    fetch('/api/admin/orders/update', {
                                                        method: 'post',
                                                        headers: { 'content-type': 'application/json' },
                                                        body: JSON.stringify({
                                                            filter: { orderid: order.orderid },
                                                            update: { $set: { waybill: e.target.value } }
                                                        })
                                                    })
                                                });
                                            }} value={order.waybill} />

                                            <SButton style={{
                                                padding: "7px 11px", height: 36,
                                                margin: 0,
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0
                                            }} onClick={async () => {
                                                await fetch('/api/admin/orders/update', {
                                                    method: 'post',
                                                    headers: { 'content-type': 'application/json' },
                                                    body: JSON.stringify({
                                                        filter: { orderid: order.orderid },
                                                        update: { $set: { waybill: order.waybill } }
                                                    })
                                                })
                                            }}><i className="fa-solid fa-save" /></SButton>
                                        </div>
                                    </td>
                                    <td style={{ padding: 0 }}>
                                        <div style={{
                                            padding: 5, fontSize: 10,
                                            color: "rgb(0,0,0)"
                                        }}>{order.user.cart.length} order items:<br /></div>
                                        {order.user.cart.map((item: any, i) => {

                                            // new paint product 2021
                                            if (item.car && item.paint) {
                                                let itemNewPaint: CartItemNewPaint = item;
                                                return <div style={{
                                                    background: "rgb(235,235,235)",
                                                    padding: "5px 5px",
                                                    marginBottom: 2,
                                                    width: 310,
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }} key={i}>
                                                    <div style={{
                                                        flex: '0 0 35px', padding: 0, marginRight: 5,
                                                        background: itemNewPaint.paint.color,
                                                        border: 'rgb(200,200,200) 1px solid',
                                                        borderRadius: 5
                                                    }} />
                                                    <div style={{ flex: 1 }}>
                                                        {itemNewPaint.car.brandName} {itemNewPaint.car.modelName}<br />
                                                        {itemNewPaint.paint.code} {itemNewPaint.paint.desc} {itemNewPaint.package}
                                                    </div>
                                                    <div>
                                                        R{itemNewPaint.price}
                                                    </div>

                                                </div>
                                            }

                                            // old paint product pre 2020
                                            if (item.brandName && item.year) {
                                                let itemOldPaint: CartItemOldPaint = item;
                                                return <div style={{
                                                    background: "rgb(235,235,235)",
                                                    padding: "5px 5px",
                                                    marginBottom: 2,
                                                    width: 310,
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }} key={i}>
                                                    <div style={{
                                                        flex: '0 0 35px', padding: 0, marginRight: 5,
                                                        background: itemOldPaint.css,
                                                        border: 'rgb(200,200,200) 1px solid',
                                                        borderRadius: 5
                                                    }} />
                                                    <div style={{ flex: 1 }}>
                                                        {itemOldPaint.brandName} {itemOldPaint.modelName} {itemOldPaint.year}<br />
                                                        {itemOldPaint.code} {itemOldPaint.desc} {itemOldPaint.package}
                                                    </div>
                                                    <div>
                                                        R {(itemOldPaint.package === 'basic') ? '360' : '480'}
                                                    </div>
                                                </div>
                                            }

                                            if (item.product) {
                                                let itemProduct: CartItemProduct = item;
                                                let image = '/placeholder.jpg';
                                                if (itemProduct.product.images[0]) {
                                                    image = itemProduct.product.images[0];
                                                }
                                                return <div style={{
                                                    background: "rgb(235,235,235)",
                                                    padding: "5px 5px",
                                                    marginBottom: 2,
                                                    width: 310,
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }} key={i}>
                                                    <div style={{
                                                        flex: '0 0 35px', padding: 0, marginRight: 5,
                                                        border: 'rgb(200,200,200) 1px solid',
                                                        borderRadius: 5,
                                                        height: 35
                                                    }}>
                                                        <img src={image} style={{ width: '100%' }} alt="" />
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        {itemProduct.product.name}
                                                    </div>

                                                    <div>
                                                        R{itemProduct.product.price}
                                                    </div>
                                                </div>
                                            }

                                            return <div style={{
                                                background: "rgb(235,235,235)",
                                                padding: "5px 10px",
                                                marginBottom: 2,
                                                width: 310
                                            }} key={i}>
                                                <pre>{JSON.stringify(item, null, 2)}</pre>
                                            </div>;

                                        })}
                                    </td>
                                    <td>
                                        <div style={{ paddingBottom: 5, fontSize: 10, color: "gray" }}>Paid:<br /></div>
                                        <span style={{ whiteSpace: "nowrap" }}>R {order.payment.amount_gross}</span>
                                    </td>
                                    <td>
                                        <div style={{ paddingBottom: 5, fontSize: 10, color: "gray" }}>Status:<br /></div>

                                        {(order.done !== true) && <>NEW</>}

                                        {(order.done) && <i
                                            className="fas fa-check"
                                            style={{ color: "#3b8c3b" }} />}

                                        <div style={{
                                            paddingTop: 10, paddingBottom: 5,
                                            fontSize: 10, color: "gray"
                                        }}>Set Status:<br /></div>

                                        <SButton style={{ padding: "10px" }} onClick={async () => {
                                            let a = await fetch('/api/admin/orders/update', {
                                                method: 'post',
                                                headers: { 'content-type': 'application/json' },
                                                body: JSON.stringify({
                                                    filter: { orderid: order.orderid },
                                                    update: { $set: { done: (order.done !== true) } }
                                                })
                                            });
                                            let b = await a.json();
                                            console.log(b);
                                            this.getOrders();
                                        }}>
                                            <i className="fa-solid fa-truck" /> DONE</SButton>

                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </STable>
                </div >
            </div>
        )
    }
}
