import React, { Component } from "react";



interface Props {
  selectCar: any;
  onCode: (code: string) => void
  clearPaintViewer: Function;
}
interface State {
  years: number[];
  brands: string[];
  models: string[];
  brandName: string;
  modelName: string;
  code: string;
  selectedModel: boolean
}

export class PaintFind extends Component<Props, State> {
  state: State = {
    brands: [],
    models: [],
    brandName: "",
    modelName: "",
    years: [],
    code: "",
    selectedModel: false
  };

  componentDidMount = () => {
    fetch("/api/brands", { method: "GET" })
      .then(response => response.json())
      .then(brands => {
        this.setState({ brands });
      })
      .catch(err => console.error(err.toString()));
  };

  selectBrand = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var brandName = e.target.value;
    this.setState({ brandName, models: [], years: [] });
    this.props.clearPaintViewer();

    fetch("/api/models", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ brandName })
    })
      .then(response => response.json())
      .then(models => {
        this.setState({ models });
      })
      .catch(err => console.error(err.toString()));
  };

  selectModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var modelName = e.target.value;
    this.setState({ modelName, selectedModel: true });
    this.props.clearPaintViewer();

    // fetch("/api/years", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ brandName: this.state.brandName, modelName })
    // })
    //   .then(response => response.json())
    //   .then((years: number[]) => {
    //     this.setState({ years });
    //   })
    //   .catch(err => console.error(err.toString()));

    var car = {
      brandName: this.state.brandName,
      modelName
    };
    this.props.selectCar(car);

  };

  // selectYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   var car = {
  //     brandName: this.state.brandName,
  //     modelName: this.state.modelName,
  //     year: e.target.value
  //   };
  //   this.props.selectCar(car);
  // };

  render() {
    return (
      <div className="">
        <div
          className="container"
          style={{ background: "rgb(8,	36,	63)", padding: 0 }}
        >
          <div style={{ paddingTop: 15 }}>
            <div className="col-12">
              <h2 style={{ color: "white" }}>FIND YOUR COLOUR:</h2>
            </div>
          </div>

          <div style={{
            borderTop: "1px solid rgb(255,255,255,0.25)",
            display: "flex",
            flexDirection: "row"
          }}>

            <div style={{ padding: 15, paddingRight: 0 }}>
              <div style={{ color: 'white' }}>MAKE:</div>
              <select
                className="form-control"
                onChange={this.selectBrand}
                defaultValue=""
                disabled={this.state.brands.length === 0}
              >
                <option value="">Select car make</option>
                {this.state.brands.map((brand) => (
                  <option value={brand} key={brand}>
                    {brand}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ padding: 15 }}>
              <div style={{ color: 'white' }}>MODEL:</div>
              <select
                className="form-control"
                onChange={this.selectModel}
                defaultValue=""
                disabled={this.state.models.length === 0}
              >
                <option value="">Select model</option>
                {this.state.models.map((model: string) => (
                  <option value={model} key={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>


            {(this.state.selectedModel) && <div style={{
              borderLeft: "1px solid rgb(255,255,255,0.25)",
              padding: 15,
              display: "flex",
              flexDirection: "column"
            }}>
              <div style={{ color: 'white', width: '100%' }}>SEARCH:</div>
              <div>
                <input
                  placeholder="ex. red, blue, CODE"
                  className="form-control"
                  value={this.state.code}
                  onChange={e => {
                    const code = e.target.value;
                    this.setState({ code });
                    this.props.onCode(code)
                  }} />
              </div>
            </div>}

          </div>




        </div>
      </div>
    );
  }
}
