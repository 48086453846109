import React from "react";
import "./slider_theme.scss"
import "./slider.scss"
import Slider from "react-slick";

export class SliderMarketing extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 750,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className=" ">
        <Slider {...settings}>
          <div><img src="/img/bg-img/g1.jpg" alt="" /></div>
          <div><img src="/img/bg-img/g2.jpg" alt="" /></div>
          <div><img src="/img/bg-img/g3.jpg" alt="" /></div>
          <div><img src="/img/bg-img/g4.jpg" alt="" /></div>
        </Slider>
      </div>

    );
  }
}
