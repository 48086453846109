import React, { Component } from 'react';

interface Props {
  getUserInfo?: Function
}
interface State { }

export class Login extends Component<Props, State> {
  state = {};

  input = (property: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let state: any = {}
      state[property] = e.target.value
      this.setState(state)
    }
  }

  login = () => {
    fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    }).then(response => response.json()).then(result => {
      if (this.props.getUserInfo) this.props.getUserInfo();
      if (result.result === 'success') { window.location.reload() }
    }).catch(err => console.error(err.toString()));

  }

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col' style={{ textAlign: 'center', paddingTop: 150, paddingBottom: 150 }}>
            <h2 style={{ paddingBottom: 20 }}>LOGIN</h2>
            <h5>username:</h5>
            <input onChange={this.input('username')} ></input><br /><br />
            <h5>password:</h5>
            <input type='password' onChange={this.input('password')}></input><br /><br />
            <button onClick={this.login} >login</button>
          </div>
        </div>
      </div >
    )
  }
}
