import React from 'react';
import './slider_theme.scss'
import './slider.scss'
import Slider from 'react-slick';
import { api } from '../../api';
import { SFPPaintPrices } from '../shared/interfaces';

interface State { prices: SFPPaintPrices | any }

export class SimpleSlider extends React.Component<{}, State> {
  state = { prices: { basic: 0, advanced: 0 } }

  constructor(props: any) {
    super(props);
    api.getPrices((err, prices) => {
      if (prices) this.setState({ prices })
    })
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 750,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true
    };


    const startingprice = (this.state.prices.basic !== 0) ? this.state.prices.basic : 0;

    if (startingprice === 0) return <div></div>

    if (startingprice !== 0) return (
      <div className='sliderPanel'>
        <div className='container-flex' style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className=''>
            <div className='' style={{ width: '100%' }} >
              <Slider {...settings}>

                <div>
                  <div className='sliderPanels' style={{ backgroundImage: 'url(/img/bg-img/hero1.jpg)' }}>

                    <div className='container' >
                      <h1 style={{ color: '#f00' }}>Touch-Up Paint Kits </h1>
                      <h1 style={{ color: '#fff' }}>Starting from R{startingprice}</h1>
                      <h6 style={{ color: '#fff' }} >
                        Shipping within South Africa starts at R160.<br />
                        Custom DIY touch-up paint kits for<br />
                        scratch and chip repairs on your car. </h6>
                    </div>

                  </div>
                </div>



                <div>
                  <div className='sliderPanels' style={{ backgroundImage: 'url(/img/bg-img/hero2.jpg)' }}>

                    <div className='container'>
                      <h1 style={{ color: '#f00' }}>Scratched your precious? </h1>
                      <h1 style={{ color: '#fff' }}>Dont Worry.</h1>
                      <h6 style={{ color: '#fff' }} >We have the perfect solution. Get started.
                        <br />Find your color and you're on your way to a flawless finish. </h6>
                    </div>

                  </div>
                </div>


                <div>
                  <div className='sliderPanels' style={{ backgroundImage: 'url(/img/bg-img/hero3.jpg)' }}>

                    <div className='container'>
                      <h1 style={{ color: '#f00' }}>Fix your "little accidents"
                        <br />in just 4 easy steps.</h1>

                      <h6 style={{ color: '#fff' }}>Our kits contain only the best quality products.
                        <br /> From the Cleaning Solution to the Hand Glaze. </h6>
                    </div>

                  </div>
                </div>

                <div>
                  <div className='sliderPanels' style={{ backgroundImage: 'url(/img/bg-img/hero4.jpg)' }}>

                    <div className='container'>
                      <h1 style={{ color: '#fff' }}>Coming Soon! </h1>

                      <h6 style={{ color: '#fff' }}>New Premium Product Range.</h6>
                    </div>

                  </div>
                </div>

                <div>
                  <div className='sliderPanels' style={{ backgroundImage: 'url(/img/bg-img/hero5.jpg)' }}>

                    <div className='container'>
                      <h1 style={{ color: '#f00' }}>Simple. Easy. Fast.</h1>

                      <h6 style={{ color: '#000' }}>Our kits have everything you need to fix<br />scratches and chips on your car.</h6>
                    </div>

                  </div>
                </div>

              </Slider>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
