import { Component } from 'react';

import { api } from '../../api';
import { SFPUserInfo } from '../shared/interfaces';
import { request } from '../../utils/requestweb';
import { UserInfo } from '../shared/interfaces/userInfo';


interface Props {
  validation: (accountFormValid: boolean) => void
}

interface State {
  info: SFPUserInfo;
  /** true/false if the system is busy saving to the server */
  saving: boolean;
  /** data changed and needs to be saved */
  changed: boolean;
  /** how long ago something changed.. defaults to page load. */
  lastchange: Date;
  /** sync status to server */
  synced: boolean;
  errors: FormError[];
}

interface FormError {
  name: string,
  description: string
}

export class Account extends Component<Props, State> {
  state = {
    info: {
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
      address1: '',
      address2: '',
      address3: '',
      postcode: ''
    },
    saving: false,
    changed: false,
    synced: false,
    lastchange: new Date(),
    errors: []
  };

  checker: any;

  constructor(props: any) {
    super(props);

    api.getaccount((err, account) => {
      this.setState({ info: account.info, synced: true }, () => {
        this.checkValidation();
      });
    });

    this.checker = setInterval(() => {
      if (this.state.changed) {
        const now = new Date();
        const milliseconds = (now.getTime() - this.state.lastchange.getTime());
        if (milliseconds > 300) { this.updateServer(); }
      }
    }, 500);
  }

  updateServer = () => {
    this.setState({ changed: false, saving: true, synced: false }, () => {
      request.post(
        '/api/user/updateinfo',
        { json: this.state.info },
        (err, r, body: any) => {
          this.checkValidation();

          if (body.ok === 1) {
            this.setState({
              changed: false,
              saving: false,
              synced: true
            }, () => {
              this.checkValidation();
            });
          }
        }
      );
    });
  }

  componentWillUnmount = () => {
    clearInterval(this.checker);
  };

  input = (property: string) => {
    return (e: any) => {
      const info: any = JSON.parse(JSON.stringify(this.state.info))// { ...this.state.info };
      info[property] = e.target.value;
      this.setState(
        { synced: false, info, changed: true, lastchange: new Date() },
        () => {
          this.checkValidation();
        }
      );

    };
  };

  checkValidation = () => {
    let valid = true;
    const errors: FormError[] = [];

    const user: UserInfo = {
      firstName: this.state.info.firstName,
      lastName: this.state.info.lastName,
      email: this.state.info.email,
      telephone: this.state.info.telephone,
      address1: this.state.info.address1,
      address2: this.state.info.address2,
      address3: this.state.info.address3,
      postcode: this.state.info.postcode
    }

    if (user.firstName.length === 0) {
      valid = false;
      errors.push({ name: 'firstName', description: 'First Name may not be empty' });
    }
    if (user.lastName.length === 0) {
      valid = false;
      errors.push({ name: 'lastName', description: 'Last Name may not be empty' });
    }

    if (!this.validateEmail(user.email)) {
      valid = false;
      errors.push({ name: 'email', description: 'Email not valid.' });
    }

    if (user.email.length === 0) {
      valid = false;
      errors.push({ name: 'email', description: 'Email may not be empty' });
    }
    if (user.telephone.length === 0) {
      valid = false;
      errors.push({ name: 'telephone', description: 'Phone Number may not be empty' });
    }
    if (user.address1.length === 0) {
      valid = false;
      errors.push({ name: 'address1', description: 'Address may not be empty' });
    }
    if (user.address2.length === 0) {
      valid = false;
      errors.push({ name: 'address2', description: 'Town/City may not be empty' });
    }
    if (user.address3.length === 0) {
      valid = false;
      errors.push({ name: 'address3', description: 'Province/State may not be empty' });
    }
    if (user.postcode.length === 0) {
      valid = false;
      errors.push({ name: 'postcode', description: 'Postcode may not be empty' });
    }

    this.setState({ errors })

    if (this.state.synced) {
      this.props.validation(valid)
    } else {
      this.props.validation(false)
    }

  }

  styleErrorToggle = (inputName: string) => {
    for (const e of this.state.errors) {
      const er: FormError = e;
      if (er.name === inputName) {
        return { width: '100%', border: '1px solid red', padding: 5 }
      }
    }
    return { width: '100%', padding: 5 }
  }

  validateEmail(email: string) {
    // not sure if this is working.
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    if (!api.account) {
      return <div>account error</div>;
    }

    return (
      <div
        className='container'
        style={{
          paddingTop: '50px',
          paddingBottom: '50px',
          background: 'rgba(0,0,0,0.05)'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: '1' }}>
            <h2>Account</h2>
          </div>
        </div>

        <div className='row padtop'>
          <div className='col-6'>
            First Name
            <br />
            <input
              onChange={this.input('firstName')}
              value={this.state.info.firstName}
              style={this.styleErrorToggle('firstName')}
            ></input>
          </div>
          <div className='col-6'>
            Last Name
            <br />
            <input
              onChange={this.input('lastName')}
              value={this.state.info.lastName}
              style={this.styleErrorToggle('lastName')}
            ></input>
          </div>
        </div>

        <div className='row padtop'>
          <div className='col-6'>
            Email
            <br />
            <input
              onChange={this.input('email')}
              value={this.state.info.email}
              style={this.styleErrorToggle('email')}
            ></input>
          </div>
          <div className='col-6'>
            Phone Number
            <br />
            <input
              onChange={this.input('telephone')}
              value={this.state.info.telephone}
              style={this.styleErrorToggle('telephone')}
            ></input>
          </div>
        </div>

        <div className='row padtop'>
          <div className='col-12'>
            Address
            <br />
            <input
              onChange={this.input('address1')}
              value={this.state.info.address1}
              style={this.styleErrorToggle('address1')}
            ></input>
          </div>
        </div>

        <div className='row padtop'>
          <div className='col-4'>
            Town/City
            <br />
            <input
              onChange={this.input('address2')}
              value={this.state.info.address2}
              style={this.styleErrorToggle('address2')}
            ></input>
          </div>
          <div className='col-4'>
            Province/State
            <br />
            <input
              onChange={this.input('address3')}
              value={this.state.info.address3}
              style={this.styleErrorToggle('address3')}
            ></input>
          </div>
          <div className='col-4'>
            Postcode
            <br />
            <input
              onChange={this.input('postcode')}
              value={this.state.info.postcode}
              style={this.styleErrorToggle('postcode')}
            ></input>
          </div>
        </div>

        <div className='row padtop'>
          <div className='col-12' style={{ textAlign: 'right' }}>
            {this.state.errors.map((e: FormError, k) => {
              return <div key={k} style={{ color: 'red' }}>{e.description}</div>
            })
            }
          </div>
        </div>

        <div className='col-12' style={{ textAlign: 'right' }}>
          {(this.state.synced) ? <div>&nbsp;</div> : <div><i className='fas fa-circle-notch fa-spin'></i></div>}
        </div>
      </div>
    );
  }
}

