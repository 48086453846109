
export function generate(count: number) {
    var _sym = 'abcdefghijklmnopqrstuvwxyz1234567890'
    var str = '';
    for (var i = 0; i < count; i++) {
        var tmp = _sym[Math.round(Math.random() * (_sym.length - 1))];
        str += "" + tmp;
    }
    return str;
}

export function rgb2hsv(r: number, g: number, b: number) {
    let rabs, gabs, babs, rr, gg, bb: number;
    let h: number = 0;
    let s: number;
    let diffc, percentRoundFn;
    rabs = r / 255;
    gabs = g / 255;
    babs = b / 255;
    let v = Math.max(rabs, gabs, babs);
    let diff: number = v - Math.min(rabs, gabs, babs);

    diffc = (c: number) => (v - c) / 6 / diff + 1 / 2;

    percentRoundFn = (num: number) => Math.round(num * 100) / 100;

    if (diff === 0) {
        h = s = 0;
    } else {
        s = diff / v;
        rr = diffc(rabs);
        gg = diffc(gabs);
        bb = diffc(babs);

        if (rabs === v) {
            h = bb - gg;
        } else if (gabs === v) {
            h = (1 / 3) + rr - bb;
        } else if (babs === v) {
            h = (2 / 3) + gg - rr;
        }
        if (h < 0) {
            h += 1;
        } else if (h > 1) {
            h -= 1;
        }
    }
    return {
        h: Math.round(h * 360),
        s: percentRoundFn(s * 100),
        v: percentRoundFn(v * 100)
    };
}