import { EventEmitter } from 'events'
import { request } from './utils/requestweb';
import { SFPPaintPrices, SFPUser } from './sfp/shared/interfaces';
import { Product } from './sfp/shared/interfaces/product';
import { APIResponse, ICategory } from '../../shared/interfaces';


export class API extends EventEmitter {
    account: SFPUser = {
        cart: [],
        cartTotal: 0,
        created: { jsonTime: '', unix: 0 },
        info: {
            firstName: '',
            lastName: '',
            email: '',
            telephone: '',
            address1: '',
            address2: '',
            address3: '',
            postcode: ''
        },
        ip: '',
        ipLoc: {},
        lastSeen: { jsonTime: '', unix: 0 },
        userAgent: '',
        uuid: '',
        admin: false
    };

    constructor() {
        super();
        this.getaccount();
    }

    /** gets our account if exists.. uses cookie serverside */
    getaccount(cb?: (err: Error | undefined, account: SFPUser) => void): void {
        request.get('/api/account/info', { json: true }, (err, r, account) => {
            this.account = account;
            // console.log(account);
            this.emit('accountUpdated', this.account);
            if (cb) cb(err, account);
        })
    }

    getPrices(cb?: (err: Error | undefined, prices: SFPPaintPrices) => void): void {
        request.get('/api/paints/prices', { json: true }, (err, r, body: any) => {
            if (cb) cb(err, body);
        })
    }

    // PRODUCTS

    getProductInfo(cb?: (err: Error | undefined, productinfo: any) => void): void {
        request.get('/api/paints/productinfo', { json: true }, (err, r, body: any) => {
            if (cb) cb(err, body);
        })
    }

    postProductAdd = async (product: Product): Promise<APIResponse<Product>> => {
        return fetch('/api/products/add', {
            method: 'post',
            body: JSON.stringify(product)
        }).then(res => res.json());
    }

    postProductDelete(product: Product, cb: (err: Error | undefined, result: any) => void) {
        request.post('/api/products/delete', { json: product }, (err, r, body) => {
            cb(err, body);
        })
    }

    // TODO remove
    // postProductUpdate(product: Product, cb: (err: Error | undefined, result: any) => void) {
    //     request.post('/api/products/update', { json: { product } }, (err, r, body) => {
    //         cb(err, body);
    //     })
    // }

    postProductUpdate = async (product: Product): Promise<APIResponse<Product>> => {
        return fetch('/api/products/update', {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ product })
        }).then(res => res.json());
    }

    getProducts = async (): Promise<APIResponse<Product[]>> => {
        return fetch('/api/products').then(res => res.json());
    }

    // CATEGORIES

    getCategories = async (body?: Partial<ICategory>): Promise<APIResponse<ICategory[]>> => {

        if (body) return fetch('/api/categories',
            {
                method: 'post',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(body)
            }).then(res => res.json());

        // get all by default
        return fetch('/api/categories').then(res => res.json());
    }

    postCategoryAdd = async (category: ICategory): Promise<APIResponse<ICategory>> => {
        console.log(category);
        return fetch('/api/categories/add', {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(category)
        }).then(res => res.json());
    }

    categoryDelete = async (category: ICategory): Promise<APIResponse<undefined>> => {
        console.log(category);
        return fetch('/api/categories/delete', {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(category)
        }).then(res => res.json());
    }

    categoryUpdate = async (category: ICategory): Promise<APIResponse<ICategory>> => {
        return fetch('/api/categories/update', {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(category)
        }).then(res => res.json());
    }

    /** gets a single product from the server */
    getProduct(id: string, cb: (err: Error | undefined, product: Product) => void) {
        request.get('/api/product/' + id, { json: true }, (err, r, body: any) => {
            cb(err, body);
        })
    }

    updateCart() {
        this.getaccount();
    }
}


const apiinstance = new API()

const globalAny: any = global;
globalAny.api = apiinstance

export const api: API = apiinstance