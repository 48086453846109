import styled from "styled-components";

export const SButton = styled.button`
    background: #103d8a;
    color: rgb(225,225,225);
    border: none;
    margin: 2px;
    border-radius: 3px;
    padding: 5px 5px;
    font-weight: bold;

    :hover {
        background: #0f4aae;
    }
`;