import { Component } from 'react';

import { SFPPaintPrices, SFPPaintInfo } from '../shared/interfaces';
import { api } from '../../api';

interface Props { }
interface State { prices: SFPPaintPrices, productinfo: SFPPaintInfo }

export class Products extends Component<Props, State> {
  state = {
    prices: { basic: 0, advanced: 0 },
    productinfo: { basic: [], advanced: [] }
  };

  componentDidMount = () => {
    api.getPrices((err, prices) => {
      if (err) console.error(err.toString());
      console.log(prices)
      this.setState({ prices })
    })

    api.getProductInfo((err, productinfo: SFPPaintInfo) => {
      this.setState({ productinfo })
    })
  }

  render() {
    if (!this.state.prices) { return null; }

    return (
      <div className='container' style={{ marginBottom: 50 }}>
        <div className='row products' style={{ paddingBottom: 50 }}>
          <div className='col-md-6'>
            <div className='product' style={{
              border: '2px solid rgba(0,0,0,0.1)',
              background: 'rgba(0,0,0,0.025)',
              borderRadius: 5
            }}>
              <div><img src='/img/basicKit.png' alt="" /></div>
              <h2 style={{ paddingTop: 12 }}>Basic Kit</h2>
              <p>
                {(this.state.productinfo.basic.map((info) => {
                  return <span key={info}>{info}<br /></span>
                }))}
              </p>
              <h4>R {this.state.prices.basic.toFixed(2)}</h4>
            </div>

          </div>
          <div className='col-md-6'>
            <div className='product' style={{
              border: '2px solid rgba(0,0,0,0.1)',
              background: 'rgba(0,0,0,0.025)',
              borderRadius: 5
            }}>
              <img src='/img/advancedKit.png' alt="" />
              <h2 style={{ paddingTop: 12 }}>Advanced Kit</h2>

              <p>
                {(this.state.productinfo.advanced.map((info) => {
                  return <span key={info}>{info}<br /></span>
                }))}
              </p>
              <h4>R {this.state.prices.advanced.toFixed(2)}</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
