import React from "react";
import Compress from "compress.js"

interface ImageUploadProps {
    /** if we should display an image set this to the uri or src/base64 string */
    value?: string
    compressOptions?: CompressOptions
    onChange: (imagebase64: string[]) => void
}

export interface CompressOptions {
    /** the max size in MB, defaults to 2MB */
    quality?: number | undefined;
    /** the quality of the image, max is 1 */
    size?: number | undefined;
    /** the max width of the output image, defaults to 1920px */
    maxWidth?: number | undefined;
    /** the max height of the output image, defaults to 1920px */
    maxHeight?: number | undefined;
    /** defaults to true, set false if you do not want to resize the image width and height */
    resize?: boolean | undefined;
}

const defaultOptions: CompressOptions = {
    size: 2,
    quality: 0.5,
    maxWidth: 300,
    maxHeight: 300,
    resize: true
}

export class ImageUpload extends React.Component<ImageUploadProps> {

    resizeFile = async (file: File) => {
        const compress = new Compress();
        const resizedImage = await compress.compress([file], this.props.compressOptions || defaultOptions)
        const img = resizedImage[0];
        const base64str = `data:${file.type};base64, ${img.data}`;
        return base64str;
        // const imgExt = img.ext
        // const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
        // return resizedFiile;
    }

    render() {
        return <div>
            <form>
                <label htmlFor="files" style={{ cursor: 'pointer' }}>
                    {this.props.children}
                </label>
                <input id="files" multiple placeholder="" type="file" style={{ display: 'none' }} accept="image/jpeg,image/png"
                    onChange={async (event) => {
                        // if no file uploaded return
                        if (!event.target.files || !event.target.files[0]) return;

                        // resize and base64 it
                        let imageBase64 = [];
                        let queue = [];

                        for (var a = 0; a < event.target.files.length; a++) {
                            queue.push(this.resizeFile(event.target.files[a]));
                        }

                        imageBase64 = await Promise.all(queue);
                        console.log(imageBase64);
                        this.props.onChange(imageBase64);
                    }} />
            </form>
        </div>
    }
}