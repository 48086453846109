import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Product } from "../../shared/interfaces/product";
import { Link } from 'react-router-dom';

export interface IProductListItemState { }
export interface IProductListItemProps {
    product: Product
}

export class ProductListItem extends React.Component<IProductListItemProps, IProductListItemState> {
    render() {
        let product = this.props.product;
        let thumbnail = '/placeholder.jpg'

        if (product.images) {
            if (product.images.length > 0) {
                thumbnail = product.images[0];
            }
        }

        if (!product.price) return <></>;

        return <Box sx={{ backgroundColor: 'rgba(0,0,0,0.025)' }}>
            <Link key={product._id} to={'/p/' + product._id} style={{ textDecoration: 'none' }}>
                <Paper sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'white',
                    borderRadius: 0,
                    boxSizing: 'border-box',
                    ':hover': {
                        cursor: 'pointer',
                        borderColor: 'secondary.main',
                        '.productListItemInfoBox': {
                            backgroundColor: 'secondary.main',
                            color: 'white',
                            transition: 'all 0.1s linear'
                        },
                        transition: 'all 0.1s linear'
                    }
                }}
                    elevation={0}
                >

                    <img src={thumbnail} style={{ marginTop: -10, marginBottom: 45, width: '100%' }} />
                    <Paper className="productListItemInfoBox" sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        p: 0.5,
                        pl: 1,
                        pr: 1,
                        borderRadius: 0,
                        backgroundColor: 'rgba(240,240,240)'
                    }}
                        elevation={0}>

                        <Typography
                            variant="subtitle2"
                        // sx={{ whiteSpace: 'nowrap' }}
                        >{product.name}</Typography>

                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>R {product.price}</Typography>
                    </Paper>
                </Paper>
            </Link>
        </Box>
    }
}