import React, { Component } from "react";

// import { PaintFull } from '../../../../../shared/interfaces'

interface Props { }
interface State {
  /** holds list of existing brands for autocomplete */
  brands: string[],
  models: string[],
  years: number[],
  brandName: string,
  modelName: string,
  year: string,
  description: string,
  code: string,
  colhex1: string,
  colhex2: string
  error?: string
  message?: string
}

export class PaintAdd extends Component<Props, State> {
  state = {
    brands: [],
    models: [],
    years: [],
    brandName: "",
    modelName: "",
    year: "",
    description: "",
    code: "",
    colhex1: "#aaaaaa",
    colhex2: "#aaaaaa",
    error: undefined,
    message: undefined
  };


  componentDidMount = () => {
    fetch("/api/brands", { method: "GET" })
      .then(response => response.json())
      .then(brands => {
        this.setState({ brands });
      })
      .catch(err => console.error(err.toString()));
  }

  selectBrand = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var brandName = e.target.value;
    this.setState({ brandName, models: [], years: [] });

    fetch("/api/models", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ brandName })
    })
      .then(response => response.json())
      .then(models => {
        this.setState({ models });
      })
      .catch(err => console.error(err.toString()));
  };

  input = (property: string) => {
    return (e: any) => {
      var state: any = {}
      state[property] = e.target.value.toUpperCase();

      /** gets model list for easier autocomplete */
      if (property === "brandName") {
        this.selectBrand(e)
      }

      this.setState(state, () => { })
    }
  }

  addPaint = () => {

    var newpaint = {
      brandName: this.state.brandName,
      modelName: this.state.modelName,
      // year: this.state.year,
      description: this.state.description,
      code: this.state.code,
      colhex1: this.state.colhex1,
      colhex2: this.state.colhex2,
    }

    fetch("/api/paints/add", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newpaint)
    }).then(response => response.json()).then(result => {
      //this.props.getUserInfo();
      if (result.error) {
        this.setState({ error: result.error })
        setTimeout(() => {
          this.setState({ error: undefined })
        }, 2000)
      }

      if (result.result === 'success') {
        this.setState({
          message: 'Success',
          brands: [],
          models: [],
          years: [],
          brandName: "",
          modelName: "",
          year: "",
          description: "",
          code: "",
          colhex1: "#aaaaaa",
          colhex2: "#aaaaaa",
          error: undefined
        });

        setTimeout(() => {
          this.setState({ message: undefined })
        }, 2000)

      }
    }).catch(err => console.error(err.toString()));
  }

  render() {
    return (
      <div className="container" style={{ background: "rgb(240,240,240)", paddingBottom: 15 }}>

        <div className="row">
          <div className="col" style={{ paddingTop: 15 }}>
            <h4>PAINT ADD</h4>
          </div>
        </div>

        <div className="row" style={{ paddingTop: 15 }}>
          <div className="col-4" >
            Brand:<br />
            <input onChange={this.input("brandName")}
              value={this.state.brandName} style={{ width: "100%" }} list="brands" />
            <datalist id="brands">
              {this.state.brands.map((brand, i) => (
                <option value={brand} key={i}>
                  {brand}
                </option>
              ))}
            </datalist>
          </div>

          <div className="col-5" >
            Model:<br />
            <input
              onChange={this.input("modelName")}
              value={this.state.modelName}
              style={{ width: "100%" }}
              list="models"
            />
            <datalist id="models">
              {this.state.models.map((model, i) => (
                <option value={model} key={i}>
                  {model}
                </option>
              ))}
            </datalist>
          </div>

          {/* <div className="col-3" >
            Year:<br />
            <input onChange={this.input("year")} value={this.state.year} style={{ width: 45 }} placeholder="20XX"></input>
          </div> */}
        </div>

        <div className="row" style={{ paddingTop: 15 }}>
          <div className="col-sm-3" >
            Code:<br />
            <input onChange={this.input("code")} value={this.state.code} style={{ width: "100%" }} ></input>
          </div>

          <div className="col-sm-6" >
            Description:<br />
            <input onChange={this.input("description")} value={this.state.description} style={{ width: "100%" }} ></input>
          </div>

          <div className="col-sm-3" >
            Color:<br />
            #&nbsp;<input type="color" onChange={this.input("colhex1")} value={this.state.colhex1}
              placeholder="FFFFFF" style={{ width: 60 }} ></input><br />
            #&nbsp;<input type="color" onChange={this.input("colhex2")} value={this.state.colhex2}
              placeholder="FFFFFF" style={{ width: 60 }} ></input>
          </div>


        </div>

        <div style={{ paddingTop: 35, display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, textAlign: "right", paddingRight: 15, paddingTop: 15 }} >
            <div style={{ color: "red" }}>{this.state.error}</div>
            <div>{this.state.message}</div>
          </div>
          <div style={{ flex: 0 }}>
            <button style={{ float: "right" }} className="blueButton" onClick={this.addPaint} >ADD</button>
          </div>
        </div>

      </div>



    )
  }
}
