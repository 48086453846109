import React, { Component } from 'react';
import { api } from '../../api';
import { Product } from '../shared/interfaces/product'
import styled from 'styled-components';
import {
  Box,
  Typography,
  Container,
  Paper,
  Button,
  CircularProgress,
  LinearProgress,
} from '@mui/material';

const ReactMarkdown = require('react-markdown')

interface IProductComponentProps { _id?: string, product?: Product }
interface IProductComponentState { _id: string, product?: Product }

const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;

  & .productInfoPanelWrapper {
    display: flex;
    flex: 2;
    flex-direction: row;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 15px;
    background: rgba(0,0,0,0.025);
  }

  & .productInfoImagePanel {
    width: 300px;
    flex: 1;

    & img {
      background: #fff;
      border: 1px solid rgba(0,0,0,0.1);
      padding: 15px;
    }

  }

  & .productInfoPanel {
    padding: 0px 20px;
    flex: 2;
  }

  & h1 {
    font-size: 28px;
    color: #000;
  }

  & h2 {
    font-size: 17px;
  }

  & .productInfoBuyPanel {
    flex: 1;
    background: #fff;
    padding: 15px;
  }

  & .productPrice {
    font-size: 28px;
    font-weight: bold;
  }

`

export class ProductComponent extends Component<IProductComponentProps, IProductComponentState> {
  state: IProductComponentState = {
    _id: '',
    product: undefined
  };

  componentDidMount = () => {
    if (this.props.product === undefined) {
      if (this.props._id) {
        api.getProduct(this.props._id, (e, product) => {
          this.setState({ product })
        })
      }
    }
  }

  getProduct = () => {
    if (this.props._id) {
      api.getProduct(this.props._id, (e, product) => {
        this.setState({ product })
      })
    }
  }

  componentDidUpdate(prevProps: IProductComponentProps, prevState: IProductComponentState) {
    if (this.props._id) {
      if (prevProps._id !== this.props._id) {
        this.getProduct();
      }
    }

    if (prevProps.product !== this.props.product) {
      if (this.props.product) this.setState({ product: this.props.product })
    }
  }

  addToCart = (product: Product) => {
    fetch('/api/cart/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ product })
    }).then(r => {
      api.updateCart();
    });
  }

  showProductDetails = () => {
    // const product: Product = this.state.product;
    return
  }

  render() {

    if (!this.state.product) return <Container><LinearProgress /></Container>

    let image = '/placeholder.jpg';

    if (this.state.product.images) {
      if (this.state.product.images.length > 0) {
        image = this.state.product.images[0];
      }
    }

    let product = this.state.product;

    if (!product) return <></>

    // DO NOT ALLOW TO BUY?
    let disabled = false;
    if (product.stockQuantity && product.stockQuantity < 1) disabled = true;
    if (!product.active) disabled = true;

    return (
      <>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', background: 'rgb(35,35,35)', mt: -10, width: 9999 }}>
          {this.state.product.images.concat(this.state.product.images).map((img, imgkey) => <Box key={imgkey} sx={{
            position: 'relative',
            backgroundColor: 'rgba(0,0,0,0.5)',
            overflow: 'hidden',
            p: "1px"
          }}>
            <img src={img} alt={`productimg_${imgkey}`} style={{
              height: 300
            }} />

          </Box>)}
        </Box> */}
        <Container>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>

            <Box>
              <img src={image} alt={`productimg_${image}`} style={{
                maxWidth: 300
              }} />

              <Box sx={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.025)', p: 2 }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  R {parseFloat(this.state.product.price).toFixed(2)}
                </Typography>

                {/* <span>FREE DELIVERY</span><br /> */}
                <Button
                  startIcon={<i className='fas fa-cart-plus' />}
                  variant="contained"
                  disabled={disabled}
                  onClick={() => { if (this.state.product) this.addToCart(this.state.product); }}
                  size="large"
                  sx={{ width: '100%' }}
                >
                  ADD TO CART
                </Button>
                {!product.active && <Typography color="error">STOCK ITEM INACTIVE</Typography>}
                <Typography variant='caption' sx={{ mt: 2 }}>{product.stockQuantity} in stock</Typography>

              </Box>
            </Box>

            <Paper sx={{ flex: 3, p: 2, background: 'rgba(0,0,0,0.05)' }} elevation={0}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>{this.state.product.name}</Typography>
              <ReactMarkdown source={this.state.product.description} escapeHtml={false} />
            </Paper>


          </Box>

        </Container>
      </>

    )
  }

}
