import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../api';
import { Product } from '../../shared/interfaces/product'
import styled from 'styled-components'

import {
    TableContainer,
    Grid,
    Paper, Table, TableHead, TableRow, TableCell, TableBody,
    Box,
    Container,
    Typography,
    Divider
} from '@mui/material';
import { ProductListItem } from './productListItem';

interface IProductsMainProps {
    /** id of product not to include in the list */
    hide?: string
}

interface IProductsMainState {
    products?: Product[];
}

// export const ProductListItem = styled.div`
// background: #ededed;
// border: 1px solid #e6e6e6;
// padding: 15px;
// margin-top: 15px;

//     text-decoration: none;

//     & .productPrice {
//         color: #f00;
//         font-weight: bold;
//     }

//     & .productName {
//         color: black;
//         font-weight: bold;
//     }

//     :hover {
//         background: #fbfbfb;
//         border: 1px solid #a3c8f8;
//     }
// `;

export class ProductsMain extends Component<IProductsMainProps, IProductsMainState> {
    state: IProductsMainState = {
        products: undefined,
    };

    componentDidMount = () => {
        api.getProducts().then(response => {
            if (response.success && response.data) this.setState({ products: response.data });
        })
    }

    addToCart = (product: Product) => {
        return () => {
            fetch('/api/cart/add', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ product })
            }).then(() => {
                api.updateCart();
            });
        };
    }


    render() {
        if (!this.state.products) return <></>;
        if (this.state.products.length < 1) return <></>;

        let productsDisplay: Product[] = this.state.products
            .filter(p => p.active)
            .filter(p => this.props.hide ? (p._id !== this.props.hide) : true)

        if (productsDisplay.length === 0) return <></>

        return (<Container>
            <Divider orientation='horizontal' />
            <Typography variant='h4' sx={{ fontWeight: 'bold', mt: 4, mb: 4 }}>
                Products:
            </Typography>
            <Grid
                container
                columns={{ xs: 2, sm: 2, md: 4 }}
                spacing={4}
            >
                {productsDisplay.map((product: Product, i) =>
                    <Grid
                        item
                        key={product._id || i}
                        xs={1}
                    >
                        <ProductListItem product={product} />
                    </Grid>
                )}
            </Grid>
        </Container>
        );
    }
}


