import React from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Typography,
    TextField
} from '@mui/material'
import { api } from '../../../../api';
import { ICategory } from '../../../../../../shared/interfaces';
import { generate } from '../../../shared/tools';

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';

import { alpha } from '@mui/material/styles';
import { CategoryEdit } from './categories_editform';
import { parentCategoriesBreadcrumb } from './categories_utils';



interface Props { }
interface State {
    categories?: ICategory[];
    edit_item_id?: string
}

export class CategoriesManager extends React.Component<Props, State> {
    state: State = {

    }

    componentDidMount = () => {
        this.getCategories();
    }

    getCategories = async () => {
        const result = await api.getCategories();
        if (result?.data) this.setState({ categories: result.data })
    }

    editItem = (category: ICategory) => {
        this.setState({ edit_item_id: category._id })
    }

    deleteItem = async (category: ICategory) => {
        await api.categoryDelete(category);
        this.getCategories();
    }

    renderCategoriesList = () => {
        if (!this.state.categories) return <CircularProgress />;

        if (this.state.categories.length === 0) return <Alert severity="info">No Categories.</Alert>

        return this.state.categories.map((category, i) => <Box
            key={category._id || i}
            sx={{
                borderBottom: "1px solid rgba(0,0,0,0.25)",
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: category._id === this.state.edit_item_id ? (theme) => alpha(theme.palette.primary.main, 0.1) : undefined
            }}
        >
            <Typography sx={{ flex: 1, m: 2 }}>
                {parentCategoriesBreadcrumb(this.state.categories, category.parent_category_id)}/{category.name}
            </Typography>

            <Button startIcon={<EditIcon />}
                variant="text"
                sx={{ m: 1 }}
                onClick={() => { this.editItem(category) }}>
                EDIT</Button>

            <Button
                variant="text"
                color="error"
                startIcon={<DeleteIcon />}
                sx={{ m: 1 }}
                onClick={() => { this.deleteItem(category) }}>
                DELETE
            </Button>

        </Box>)
    }

    render() {
        return <>
            <Typography variant="caption" color="primary" sx={{ ml: 0.5 }}>UNDER DEVELOPMENT</Typography>
            <Typography variant="h4">Categories Manager</Typography>


            <Button
                startIcon={<i className='fas fa-plus' />}
                variant="text"
                sx={{ mb: 2 }}
                onClick={async () => {
                    const response = await api.postCategoryAdd({
                        name: 'category' + generate(5),
                        slug: '',
                        parent_category_id: undefined
                    });


                    await this.getCategories();

                    if (response?.data?._id) this.setState({ edit_item_id: response.data._id });

                }}>  NEW CATEGORY</Button>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: 1 }}>
                    {this.renderCategoriesList()}
                </Box>

                <Box sx={{ flex: 1 }}>
                    {(this.state.edit_item_id) && <Box sx={{ flex: 1 }}>
                        <CategoryEdit _id={this.state.edit_item_id}
                            onDone={() => {
                                this.setState({ edit_item_id: '' });
                                this.getCategories();
                            }}
                        />
                    </Box>}
                </Box>
            </Box>
        </>
    }
}

