import { Box } from "@mui/material";
import { Component } from "react";
import styled from "styled-components";
import { sfpColours } from "../sfp";

export class Footer extends Component {
    state = {};

    render() {
        return (
            <Box style={{ paddingTop: "50px", paddingBottom: "100px", height: "100%" }} sx={{ background: sfpColours.midBlue }}>
                <div className="container footerPanel">

                    <div className="row">
                        <div className="col-6">
                            {/* <i className="fas fa-phone"></i> +27 63-694-9046 <br /> */}
                            <a href="mailto:info@scratchfixpro.co.za"><i className="fas fa-envelope"></i>  info@scratchfixpro.co.za</a><br />
                            <div style={{ marginTop: 10 }}><span><i className="fas fa-phone"></i> <span style={{ color: 'white' }}>073 379 8822</span></span></div>

                            <div style={{ display: 'flex', flexDirection: 'row', color: 'white', marginTop: 10 }}>
                                <div><i className="fas fa-map-marker"></i> </div>
                                <div style={{ flex: 1 }}>
                                    <p style={{ color: 'white', paddingLeft: 7, lineHeight: 1.3 }}>
                                        G.C Building, Unit 2,<br />
                                        14 Bolton Road<br />
                                        Voorbaai<br />
                                        Mosselbaai<br />
                                        6506<br />
                                        Western Cape<br />South Africa
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col-6" style={{ textAlign: "right" }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <div className="social"><a href="https://www.facebook.com/ScratchFixPro/"><i className="fab fa-facebook-square"></i></a></div>
                                    <div className="social"><a href="https://www.instagram.com/ScratchFixPro/"><i className="fab fa-instagram"></i></a></div>
                                    <div className="social"><a href="https://www.youtube.com/watch?v=OEc4SghC97A"><i className="fab fa-youtube"></i></a></div>
                                </div>

                                <div style={{ fontSize: 11, opacity: 0.75 }}>
                                    <a href="/legal/SFP_Responsible_Disclosure_Policy.pdf">Responsible Disclosure</a><br />
                                    <a href="/legal/SFP_Standard_Returns_Policy.pdf">Standard Returns</a><br />
                                    <a href="/legal/SFP_Privacy_Policy.pdf">Privacy</a>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </Box >

        )
    }

}


export const Links = styled.a`
    color: red;
    :hover {
        color: blue;
    }
`