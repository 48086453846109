import { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./scratchfixpro.scss";

import { NavBar } from "./components/navBar";
import { SimpleSlider } from "./slider/slider";
import { Paint } from "./components/paint/paint";
import { Guide } from "./components/guide";
import { Cart } from "./components/cart";
// import { Banner } from './components/banner'
import { ProductComponent } from "./components/product";
import { Products } from "./components/products";
import { Marketing } from "./components/marketing";
import { Admin } from "./components/admin/admin";
import { Footer } from "./components/footer";
import { api } from "../api";
import { SFPUser } from "./shared/interfaces";
import { ProductsMain } from "./components/products/productsMain";
import { FAQPage } from "./components/faq";
import {
  Container,
  createTheme,
  CssBaseline,
  Divider,
  ThemeProvider,
  Typography,
} from "@mui/material";

interface State {
  maintainance: boolean;
  account: SFPUser;
  loading: boolean;
}

export const sfpColours = {
  midBlue: "#1d294b",
};

export class ScratchFixPro extends Component<{}, State> {
  state: State = {
    maintainance: false,
    loading: true,
    account: api.account,
  };

  componentDidMount() {
    this.getAccount();

    api.on("accountUpdated", (account) => {
      this.setState({ account });
    });
  }

  getAccount = (cb?: any) => {
    api.getaccount((err, account) => {
      this.setState({ account, loading: false });
      if (cb) {
        cb(account);
      }
    });
  };

  home = () => {
    return (
      <div>
        <SimpleSlider />
        <Paint updateCart={this.getAccount} />
        <Products />

        <Container>
          <ProductsMain />
        </Container>
        <hr />

        <Marketing />
        {/* <Banner /> */}
      </div>
    );
  };

  guide = () => {
    return (
      <div>
        <Guide />
      </div>
    );
  };

  cart = () => {
    return (
      <div>
        <Cart />
      </div>
    );
  };

  product = ({ match }: any) => {
    return (
      <div style={{ marginTop: 80 }}>
        <ProductComponent _id={match.params._id} />
        <ProductsMain hide={match.params._id} />
      </div>
    );
  };

  admin = ({ match }: any) => {
    return (
      <div>
        <Admin match={match} />
      </div>
    );
  };

  loading = () => {
    return (
      <div style={{ textAlign: "center", paddingTop: 200 }}>
        <img src="/logo.png" alt="logo" />
        <p
          style={{
            color: "white",
            paddingTop: 50,
            fontWeight: "normal",
            fontSize: "18px",
          }}
        >
          LOADING...
        </p>
      </div>
    );
  };

  message = (messageIn: string) => {
    return () => {
      return (
        <div
          className="container"
          style={{ padding: 100, textAlign: "center" }}
        >
          <h2>{messageIn}</h2>
        </div>
      );
    };
  };

  render() {
    if (this.state.loading) {
      return this.loading();
    }

    // TODO maintainance mode temporary
    // if (window.location.pathname !== '/admin') {

    //     if (!this.state.account) {
    //         return <div style={{ color: 'white', padding: 50 }}>System maintainance. Please check back later.</div>
    //     }

    //     if (this.state.account.admin !== true) {
    //         return <div style={{ textAlign: 'center', paddingTop: 200 }}>
    //             <p style={{
    //                 color: 'white', paddingTop: 25,
    //                 fontWeight: 'normal',
    //                 fontSize: '18px'
    //             }}>Maintainance in progress. Please try again later.</p>
    //             <img src='/logo.png' alt="logo" style={{ paddingTop: 100 }} />
    //         </div>
    //     }
    // }

    const xfpTheme = createTheme({
      palette: {
        // mode: 'dark',
        primary: {
          main: "#324680",
        },
        secondary: {
          main: "#e3433f",
          contrastText: "rgba(255,255,255,0.87)",
        },
      },
    });

    return (
      <div className="App" style={{ height: "100%", overflow: "hidden" }}>
        {/* <div
          style={{
            background: "#f72e35",
            color: "white",
            padding: 10,
            textAlign: "center",
          }}
        >
          PLEASE NOTE ALL ORDERS PLACED BETWEEN 21 DEC 2022 - 10 JAN 2023 WILL
          ONLY BE PROCESSED AFTER THIS TIME!! WE WISH YOU A HAPPY &amp; BLESSED
          FESTIVE SEASON!!
        </div> */}

        <ThemeProvider theme={xfpTheme}>
          <CssBaseline />

          <Router>
            <div style={{ background: "#fff" }}>
              <NavBar
                account={this.state.account}
                updateCart={this.getAccount}
              />
              <div style={{ height: 50 }}></div>
              {/* <div style={{ height: 155 }}></div> */}
              <Route exact path="/" component={this.home} />
              <Route exact path="/faq" component={FAQPage} />
              <Route exact path="/guide" component={this.guide} />
              <Route
                exact
                path="/cart"
                component={this.cart}
                account={this.state.account}
              />

              <Route
                exact
                path="/products"
                component={() => <ProductsMain />}
              />
              <Route exact path="/p/:_id" component={this.product} />

              <Route exact path="/admin" component={this.admin} />
              <Route exact path="/admin/paintadd" component={this.admin} />
              <Route exact path="/admin/products" component={this.admin} />
              <Route exact path="/admin/categories" component={this.admin} />

              <Route
                path="/payment/success"
                component={this.message("Success")}
              />
              <Route
                path="/payment/cancel"
                component={this.message("Cancelled")}
              />
              <div style={{ height: 200 }}></div>
            </div>
          </Router>

          <Footer />
        </ThemeProvider>
      </div>
    );
  }
}
