import { Component } from 'react';
import { Product } from '../../../shared/interfaces/product';
import { api } from '../../../../api';
import { ProductEdit } from './product_edit'
import {
    Box,
    Typography,
    Button,
    IconButton,
    Switch
} from '@mui/material'

import {

    Edit as EditIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';


import { generate } from '../../../shared/tools';
import { ICategory } from '../../../../../../shared/interfaces';
import { parentCategoriesBreadcrumb } from '../categories/categories_utils';

// https://mui.com/x/react-data-grid/#commercial-version
import {
    useGridApiRef,
    DataGridPro,
    GridApi,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridEvents,
    GridRowId,
    GridRowModel,
    LicenseInfo,
    GridSortModel,
    GridSortItem,
    GridFilterModel,
    GridLinkOperator
} from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey('11dfa392be05d10d58887edf4f20e775T1JERVI6NDE2MjgsRVhQSVJZPTE2ODEzMzgwODU1NTIsS0VZVkVSU0lPTj0x');

interface State {
    addPostResult: string | undefined;
    products: Product[];
    editProductId?: string;
    categories?: ICategory[];
}

export class ProductManager extends Component<{}, State> {
    state: State = {
        addPostResult: undefined,
        products: [],
        // editProductId: '5e3ecb7fd0fe162ae08f0571'
        editProductId: undefined,
        categories: []
    };

    componentDidMount = () => {
        this.updateProductList();
        this.getCategories();
    }

    getCategories = async () => {
        const result = await api.getCategories();
        if (result?.data) this.setState({ categories: result.data })
    }

    updateProductList = async () => {
        const response = await api.getProducts();
        if (response.success && response.data) this.setState({ products: response.data });
    }

    deleteProduct = (product: Product) => {
        return () => {
            api.postProductDelete(product, () => {
                this.updateProductList();
            })
        }
    }

    editProduct = (product: Product) => {
        return () => {
            if (product._id) this.setState({ editProductId: product._id })
        }
    }

    render() {
        return (
            <>
                <Typography variant='h5' sx={{ pb: 2 }}>PRODUCT MANAGER</Typography>

                {this.state.editProductId === undefined && <>
                    <Button
                        startIcon={<i className='fas fa-plus' />}
                        variant="text"
                        sx={{ mb: 2 }}
                        onClick={async () => {
                            const newProduct = await api.postProductAdd({
                                name: 'untitled_' + generate(5),
                                description: '',
                                price: '',
                                images: []
                            });

                            await this.updateProductList();
                            if (newProduct?.data?._id) this.setState({ editProductId: newProduct.data._id });
                        }}>  NEW PRODUCT</Button>

                    <Box sx={{ minHeight: 400 }}>
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row._id}
                            onRowClick={(props) => {
                                console.log(props.row);
                            }}
                            columns={[
                                {
                                    field: 'active',
                                    headerName: 'Active',
                                    width: 75,
                                    renderCell: (props) => <Switch checked={props.row.active} onClick={async (event: any) => {
                                        let product = props.row;
                                        product.active = event.target.checked;
                                        await api.postProductUpdate(product);
                                        this.updateProductList();
                                    }} />
                                },
                                {
                                    field: 'images',
                                    headerName: 'Image',
                                    renderCell: (props) => <img style={{ width: '55px' }} alt="thumbnail" src={props.row.images && props.row.images[0] ? props.row.images[0] : '/placeholder.jpg'} />,
                                    width: 70
                                },
                                {
                                    field: 'brand',
                                    headerName: 'Brand',
                                },
                                {
                                    field: 'name',
                                    headerName: 'Name',
                                    width: 200,
                                    flex: 1
                                },
                                {
                                    field: 'category_id',
                                    headerName: 'Category',
                                    renderCell: (props) => <span>{parentCategoriesBreadcrumb(this.state.categories, props.row.category_id)}</span>,
                                    flex: 1
                                },
                                {
                                    field: 'price',
                                    headerName: 'Price',
                                },
                                {
                                    field: 'sku',
                                    headerName: 'SKU',
                                },
                                {
                                    field: 'stockQuantity',
                                    headerName: 'Stock QTY',
                                },
                                {
                                    field: '_id',
                                    headerName: 'Actions',
                                    renderCell: (props) => <>
                                        <IconButton
                                            color="primary"
                                            sx={{ m: 1 }}
                                            onClick={this.editProduct(props.row as Product)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            color="error"
                                            sx={{ m: 1 }}
                                            onClick={this.deleteProduct(props.row as Product)} >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>,
                                    width: 200
                                },
                            ]}
                            rows={this.state.products} />
                    </Box>
                </>}

                {(this.state.editProductId) && <Box sx={{ flex: 1 }}>
                    <ProductEdit _id={this.state.editProductId}
                        onDone={() => {
                            this.setState({ editProductId: undefined });
                            this.updateProductList();
                        }}
                    />
                </Box>}

            </>
        )
    }
}
