import { Box } from "@mui/material";

import { Order } from "../../../../../shared/interfaces";

import {
    ChartDataShape,
    Line,
    LineChart,
    LineSeries
} from "reaviz";

export function processDataCumulative(orders: Order[]) {

    var procdata: any = {
        data: [],
        min: 0,
        max: 100
    };

    var cumulativedata = [];

    var runningtotal = 0;

    orders = orders.sort((a, b) => {
        var atime = new Date(a.created.jsonTime).getTime()
        var btime = new Date(b.created.jsonTime).getTime()
        return atime - btime
    })

    for (var orderx of orders) {
        var order: Order = orderx;
        runningtotal += Math.round(parseFloat(order.payment.amount_net));
        var entry: ChartDataShape = {
            key: new Date(order.created.jsonTime),
            data: runningtotal
        }
        if ((entry.key) && (entry.data)) {
            cumulativedata.push(entry);
        }
    }

    procdata.max = runningtotal + 1000;

    return {
        min: procdata.min,
        max: procdata.max,
        data: [{ key: "cumulative", data: cumulativedata }]
    };

}


export function processDataPerformance(orders: Order[]) {

    var procdata: any = {
        data: [],
        min: 0,
        max: 200
    };

    var data: ChartDataShape[] = [];

    ////////////
    // find data start and end;
    let dataStart = new Date();
    let dataEnd = new Date(); // now.

    orders = orders.sort((a, b) => {
        var atime = new Date(a.created.jsonTime);
        var btime = new Date(b.created.jsonTime);
        if (atime.getTime() < dataStart.getTime()) { dataStart = new Date(atime); }
        if (btime.getTime() < dataStart.getTime()) { dataStart = new Date(btime); }
        return atime.getTime() - btime.getTime()
    })

    // for (var orderx of orders) {
    //     var order: Order = orderx;
    //     // runningtotal += Math.round(parseFloat(order.payment.amount_net));
    //     var entry = {
    //         key: new Date(order.created.jsonTime),
    //         data: Math.round(parseFloat(order.payment.amount_net))
    //     }
    //     if ((entry.key) && (entry.data)) {
    //         data.push(entry);
    //     }
    // }

    var entry: ChartDataShape = {
        key: dataStart,
        data: 0
    }
    data.push(entry)

    // LOOP OVER DAYS/WEEKS/MONTHS etc..

    const timeScale = (365.25 / 12) * 24 * 60 * 60 * 1000;
    let timediff = (dataEnd.getTime() - dataStart.getTime()) / timeScale;

    // LOOP OVER ORDERS.

    for (var a = 0; a < timediff; a++) {
        let loopStart = new Date(dataStart.getTime() + (a * timeScale))
        let loopEnd = new Date(dataStart.getTime() + ((a + 1) * timeScale))

        // loop over orders and check if its inside this window period.
        let counttotalForPeriod = 0;
        orders.forEach(o => {
            let oDate = new Date(o.created.jsonTime);
            if ((oDate.getTime() > loopStart.getTime()) && (oDate.getTime() < loopEnd.getTime())) {
                counttotalForPeriod += Math.round(parseFloat(o.payment.amount_net));
            }
        })

        var entry: ChartDataShape = {
            key: loopEnd,
            data: counttotalForPeriod
        }
        data.push(entry)
    }




    procdata.max = 5000;

    return {
        min: procdata.min,
        max: procdata.max,
        data: [{ key: "performance", data }]
    };

}



export const Graph = (props: { data: any }) => {

    if (!props.data) return <></>

    return (<Box sx={{ height: 500, pt: 4, pb: 4 }}>
        <LineChart
            series={
                <LineSeries
                    type="grouped"
                    line={
                        <Line
                            strokeWidth={3}
                        />
                    }

                />
            }
            data={props.data}
        />
    </Box>)
}

