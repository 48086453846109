import {
    AssignmentTwoTone,
    HomeTwoTone,
    Logout,
    MenuTwoTone,
    PaletteTwoTone,
    PersonAdd,
    QuestionMarkTwoTone,
    Security,
    SecurityTwoTone,
    Settings,
    ShoppingBagTwoTone,
    ShoppingCartRounded,
    ShoppingCartSharp,
    StoreTwoTone
} from '@mui/icons-material';

import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CartButton } from './cartButton';
import { TopBar } from './topBar';

interface Props {
    account: any
    updateCart: Function
}
interface State {
    width: number,
    menuOpen: boolean
    /** on menu click we need the button location for the popup menu */
    menuAnchor?: any
}

export class NavBar extends Component<Props, State> {
    state: State = {
        width: 0,
        menuOpen: false
    };

    wrapper: HTMLDivElement | null = null;

    componentDidMount = () => {
        window.addEventListener('resize', this.updateSize)
    }

    updateSize = () => {
        console.log('resize')
        if (this.wrapper) {
            if (this.wrapper.offsetWidth) {
                let width = this.wrapper.offsetWidth;
                if (width !== this.state.width) {
                    this.setState({ width });
                }
            }
        }
    }

    // admin = () => {
    //     if (this.isAdmin()) {
    //         return (<RouterLink to='/admin' ><div className='navbarButton'>Admin</div></RouterLink>)
    //     }

    //     return <></>;
    // }

    isAdmin = () => {
        if (this.props.account?.admin) return true;
        return false;
    }

    renderNav() {

        let cartItems = 0;
        if (this.props.account) {
            if (this.props.account.cart) {
                cartItems = this.props.account.cart.length;
            }
        }

        if (this.state.width === undefined) return null;

        let mobile = this.state.width < 800;

        return <>
            <AppBar sx={{
                backgroundColor: '#1a2a4e',
                display: 'flex', flexDirection: 'row', p: 1,
                pr: 2
            }}>
                <Button
                    component={RouterLink}
                    to='/' >
                    <img src='/logo.png' alt="scratchfixpro" style={{ height: '30px' }} />
                </Button>


                <Tooltip title="Proudly South African" arrow>
                    <Avatar alt="South Africa" src="/flag_za.png"
                        sx={{ width: 24, height: 24, mt: 1.5, mr: 2 }}
                    />
                </Tooltip>

                {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuTwoTone />
        </IconButton> */}
                {/* <Typography variant="h6" color="inherit" component="div">
            Photos
        </Typography> */}




                {mobile
                    ? <>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton
                            // color="inherit" 
                            sx={{ ml: 2, color: "white" }}
                            component={RouterLink} to="/cart">
                            <Badge badgeContent={this.props.account?.cart?.length || 0} color="secondary">
                                <ShoppingCartRounded />
                            </Badge>
                        </IconButton>

                        <IconButton
                            edge="start"
                            // color="inherit"

                            onClick={e => {
                                this.setState({ menuOpen: true, menuAnchor: e.currentTarget });
                            }}
                            aria-label="menu"
                            sx={{ ml: 2, color: "white" }}>
                            <MenuTwoTone />
                        </IconButton>

                    </>
                    : <>


                        {this.isAdmin() && <>
                            <Button
                                component={RouterLink}
                                to="/admin"
                                sx={{ ml: 1, color: 'white' }}
                                // size="small"
                                variant="contained">
                                Admin
                            </Button>
                        </>}
                        <Box sx={{ flex: 1 }} />

                        <Button
                            component={RouterLink}
                            to="/"
                            sx={{ ml: 1, color: 'white' }}
                            // size="small"
                            variant="text">
                            Home
                        </Button>
                        <Button
                            component={RouterLink}
                            to="/guide"
                            sx={{ ml: 1, color: 'white' }}
                            // size="small"
                            variant="text">
                            User Guide
                        </Button>
                        <Button
                            component={RouterLink}
                            to="/faq"
                            sx={{ ml: 1, color: 'white' }}
                            // size="small"
                            variant="text">
                            FAQ
                        </Button>
                        <Button
                            component={RouterLink}
                            to="/products"
                            sx={{ ml: 1, color: 'white' }}
                            // size="small"
                            variant="text">
                            Products
                        </Button>

                        <Button
                            // color="secondary"
                            component={RouterLink}
                            to="/cart"
                            startIcon={<Badge
                                badgeContent={this.props.account?.cart?.length || 0}
                                color="secondary">
                                <ShoppingCartRounded />
                            </Badge>}
                            sx={{ ml: 1, color: 'white', ':hover': { color: 'white' } }}
                            // size="small"
                            color={(this.props.account?.cart?.length || 0) ? 'secondary' : undefined}
                            variant="outlined"
                        >Cart</Button>
                    </>}


            </AppBar>

            <Menu
                anchorEl={this.state.menuAnchor}
                id="account-menu"
                open={this.state.menuOpen}
                onClose={() => { this.setState({ menuOpen: false }); }}
                onClick={() => { this.setState({ menuOpen: false }); }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                {/* <MenuItem
                    component={RouterLink}
                    to="/cart"
                >
                    <ListItemIcon>

                        <Badge
                            badgeContent={this.props.account?.cart?.length || 0}
                            color="secondary">
                            <ShoppingCartRounded fontSize="small" />
                        </Badge>
                    </ListItemIcon>
                    Cart
                </MenuItem> */}

                <MenuItem
                    component={RouterLink}
                    to="/"
                >
                    <ListItemIcon>
                        <HomeTwoTone fontSize="small" />
                    </ListItemIcon>
                    Home
                </MenuItem>

                <MenuItem
                    component={RouterLink}
                    to="/"
                >
                    <ListItemIcon>
                        <PaletteTwoTone fontSize="small" />
                    </ListItemIcon>
                    Paint
                </MenuItem>

                <MenuItem
                    component={RouterLink}
                    to="/products"
                >
                    <ListItemIcon>
                        <ShoppingBagTwoTone fontSize="small" />
                    </ListItemIcon>
                    Products
                </MenuItem>

                <MenuItem
                    component={RouterLink}
                    to="/guide"
                >
                    <ListItemIcon>
                        <AssignmentTwoTone fontSize="small" />
                    </ListItemIcon>
                    User Guide
                </MenuItem>

                <MenuItem
                    component={RouterLink}
                    to="/faq"
                >
                    <ListItemIcon>
                        <QuestionMarkTwoTone fontSize="small" />
                    </ListItemIcon>
                    FAQ
                </MenuItem>

                {this.isAdmin() && <>
                    <Divider />
                    <MenuItem
                        component={RouterLink}
                        to="/admin">
                        <ListItemIcon>
                            <SecurityTwoTone fontSize="small" />
                        </ListItemIcon>
                        Admin
                    </MenuItem>
                </>
                }
            </Menu>

        </>

        if (this.state.width < 780) {
            return <>
                <div className='menuPanel'>
                    <div className=' container'>
                        <div className='row' style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <div className='col' style={{ flex: '0 0 200px', paddingLeft: 20 }}>
                                <RouterLink to='/' ><img src='/logo.png' alt="" /></RouterLink>
                            </div>
                            <div className='col' style={{}}>
                                <RouterLink to='/cart' >
                                    <CartButton cartItems={cartItems} />
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div></>
        }

        // WIDE VERSION
        return <><TopBar />
            <div className='menuPanel'>
                <div className=' container'>
                    <div className='row' style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <div className='col' style={{ flex: '0 0 200px', paddingLeft: 20 }}>
                            <RouterLink to='/' ><img src='/logo.png' alt="" /></RouterLink>
                        </div>
                        <div className='col' style={{}}>

                            <RouterLink to='/cart' >
                                <CartButton cartItems={cartItems} />
                            </RouterLink>

                            <RouterLink to='/products' ><div className='navbarButton'>Products</div></RouterLink>
                            <RouterLink to='/faq' ><div className='navbarButton'>FAQ</div></RouterLink>
                            <RouterLink to='/guide' ><div className='navbarButton'>User Guide</div></RouterLink>

                            {/* {this.admin()} */}
                            <RouterLink to='/' ><div className='navbarButton'>Home</div></RouterLink>
                        </div>
                    </div>
                </div>
            </div></>

    }

    render() {


        return (
            <div ref={el => {
                if (el) if (!this.wrapper) {
                    this.wrapper = el;
                    this.updateSize();
                }
            }}>
                {this.renderNav()}
            </div>
        )
    }
}
