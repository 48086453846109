import { Component } from "react";
import { PaintAdd } from "./paintadd"

export class PaintManager extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col" style={{ paddingTop: 15 }}>
              <h3>PAINT MANAGER</h3>
            </div>
          </div>
        </div >

        <PaintAdd />
        <br />
      </div>

    )
  }
}
