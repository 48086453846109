import { Component } from "react";

export class Guide extends Component {
  state = {};

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="guide">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2>SCRATCH FIX PRO - TOUCH UP PAINT KIT</h2>
                <h1>USER GUIDE</h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="bordersTopBot">
                <span className="bold red">NB. </span>
                <span className="bold italic">
                  Scratch Fix Pro – Touch Up Paint Kit
                </span>{" "}
                is to be used at own risk. We take no responsibility for the
                incorrect application of the product. Please follow the
                instructions carefully to ensure the best results.
                <span className="bold italic">
                  Scratch Fix Pro – Touch Up Paint Kit
                </span>{" "}
                provides a cosmetic finish to make your car look neater and
                cover up the appearance of scratches and chips. For a perfect
                seamless finish visit a professional auto body shop.
              </div>
            </div>
          </div>


          <div className="row" style={{ padding: 35 }}>
            <div className="col-12">
              <iframe title="youtube" width="560" height="315" src="https://www.youtube.com/embed/OEc4SghC97A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>



          <div className="row" style={{ paddingTop: 50 }}>
            <div className="col-12">
              <div className="guideBlock">
                <img
                  alt="cleaning"
                  src="/img/Step_1.png"
                  style={{ width: "27%", float: "right" }}
                />

                <h3 className="guideBlockHeading">
                  Step 1 : Cleaning the application area
                </h3>

                <p>
                  Ensure that the application surface you want to repair has
                  been cleaned thoroughly using our cleaning solution
                  <span className="bold italic">(Prep Solution)</span>.{" "}
                </p>

                <p>
                  Wrap the paper cloth around the rubber block found in the kit.
                  Apply a few drops of
                  <span className="bold italic">Prep Solution</span> to the
                  paper cloth. Gently clean the application area with small
                  strokes.
                </p>

                <p>
                  Make sure to only apply the
                  <span className="bold italic">Prep Solution</span> to the
                  application area as it can cause hazy spots on your paint
                  finish, if applied to large areas.
                </p>

                <p>
                  Make sure all dirt, wax and polish are removed and surface is
                  ready for the touch up paint.
                </p>

                <div style={{ clear: "both" }} />
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: 50 }}>
            <div className="col-12 ">
              <div className="guideBlock">
                <h3 className="guideBlockHeading">
                  Step 2 : Applying the custom made paint
                </h3>
                <img
                  alt="apply"
                  src="/img/Step_2.png"
                  style={{ width: "50%", float: "right" }}
                />
                <p>
                  Apply your <span>custom-made paint</span> on the repair area,
                  in a thin even layer. If necessary, smear the paint flat
                  across the chipped or scratched area with your finger or thumb
                  whilst wearing the protective glove. Make sure the paint is
                  smeared out flat. Then leave for <span>10 min to dry</span>.
                </p>
                <p>
                  When the paint is dry, apply a small amount of{" "}
                  <span>Prep Solution</span> to the towel in the kit. Apply
                  light pressure and run the towel across the surface to remove
                  excess paint. Make sure not to press the towel too hard onto
                  the surface, let the <span>Prep Solution</span> do the
                  blending work.
                </p>
                <p>
                  Repeat the step until all the excess paint is removed around
                  the chip/scratch. <br />
                  Leave to dry for <span>5 min</span>.
                </p>
                <p>
                  Make sure to only apply the <span>Prep Solution</span> to the
                  application area as it can cause hazy spots on your paint
                  finish, if applied to large areas.
                </p>
                <div style={{ clear: "both" }} />
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "50px" }}>
            <div className="col-12">
              <div>
                <span
                  className="red bold italic"
                  style={{ fontSize: "25px", lineHeight: "30px" }}
                >
                  Cosmetic Finishes : Waxing and polishing are a cosmetic
                  finishings, it is not a requirement for the touch up
                  applications, but will improve the over all results. If you
                  are using our Basic Kit, you can use any polish or wax to
                  complete steps 3 and 4.
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "50px" }}>
            <div className="col-12">
              <div className="guideBlock">
                <img
                  alt="rubbing"
                  src="/img/Step_3.png"
                  style={{ width: "30%", float: "right" }}
                />
                <h3 className="guideBlockHeading">
                  Step 3 : Applying Rubbing Compound
                </h3>
                <p>
                  <span>Rubbing Compound</span> does contain abrasives and
                  additives.
                </p>
                <p>It is a treatment for perfect colour transition.</p>
                <p>
                  Apply the <span>Rubbing Compound</span> on the sponge found in
                  the kit and polish gently in circular motions. Make sure the
                  sponge is dry.
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "50px" }}>
            <div className="col-12">
              <div className="guideBlock">
                <img
                  alt="glaze"
                  src="/img/Step_4.png"
                  style={{ width: "28%", float: "right" }}
                />
                <h3 className="guideBlockHeading">
                  Step 4 : Applying Hand Glaze
                </h3>

                <p>
                  Apply a small amount of the <span>Hand Glaze</span> to the
                  microfiber cloth and smoothly apply over the entire
                  application area.{" "}
                </p>

                <p>
                  It is recommended that you wax the entire panel for the best
                  visual results.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
