import { Component } from "react";

interface Props { }

interface State { }

export class TopBar extends Component<Props, State> {
    state = {};

    render() {
        return (
            <div className="topbar" style={{ background: "#081f3e", color: "#b5b5b5" }}>
                <div style={{ maxWidth: "1140px", margin: "0 auto", paddingTop: 15, paddingBottom: 15, display: "flex", flexDirection: "row" }}>

                    <div className="col" style={{ flex: "1" }}>
                        <span >Welcome to <a href="https://scratchfixpro.co.za/">Scratch Fix Pro</a></span>
                    </div>
                    <div className="col" style={{ flex: "1", textAlign: "right" }}>
                        <img alt="South Africa flag" src="/flag_za.png" height="20" /><span style={{ paddingLeft: 10 }}>
                            For Support <strong style={{ color: 'white' }}>073 379 8822</strong> or mail to <a style={{ fontWeight: 'bold' }} href="mailto:info@scratchfixpro.co.za">info@scratchfixpro.co.za</a></span>
                    </div>

                </div>
            </div>
        )
    }

}
