import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SliderMarketing } from "../slider/slider_marketing"

export class Marketing extends Component {
  state = {};

  render() {
    return (
      <div className="container marketing">
        <div className="row">
          <div className="col-md-4">
            <h2>We have come a long way in recent years</h2>
            <p>Modern car stone chip and scratch touch up chemistry. Our all in one, easy to
              use kits delivers stunning results and you can do it yourself. Each kit contains
              a custom formulated paint to match your car’s colour exactly according to
              factory specification.</p>
          </div>
          <div className="col-md-4">
            <h5>DIY</h5>
            <p>Our kits are easy to use, with simple instructions included in each kit. We also
              have instructional videos. See pack for details.</p>
            <h5 style={{ paddingTop: 20 }}>Wide variety of colours</h5>
            <p>Any car. Any Model. Any year. <br /> We have almost 80 000 factory specific
              colours to choose from.</p>
          </div>
          <div className="col-md-4">
            <h5>Colour Match</h5>
            <p>Touch up work doesn't look good if the colour doesn't match. We ensure perfect
              colour code match that`s found on your vehicle. We use factory specifications to
              formulate your custom paint.</p>
            <h5>High Quality Products</h5>
            <p>Our kits contain only the best quality products. From the Cleaning solution to the
              Hand Glaze. We've made sure that you get the best results by providing the best
              possible products.</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12"><SliderMarketing /></div>
        </div>

        <div className="row" style={{ paddingTop: 50 }}>
          <div className="col-md-6">
            <h2 style={{ fontSize: "40px" }}>STEP BY STEP INSTRUCTIONS.</h2>
            <p>We make it easy for you. All our kits contain a step by step User Guide. We strongly
              recommend that you go through the User Guide steps to obtain the best application
              results.</p>
            <Link to="/guide"><div className="blueButton" >
              <i className="fas fa-book"></i> User Guide</div></Link>
          </div>
          <div className="col-md-6">
            <img src="/img/marketing_001.png" alt="" style={{ width: "100%" }} /></div>
        </div>

        <div className="row" style={{ paddingTop: 50 }}>
          <div className="col-md-6">
            <p>
              With over 25 years of auto body recon experience,
              <a href="https://www.mrscratch.co.za/">Mr. Scratch</a> has been a leader in the
              auto body repair industry.
            </p>
            <p>
              Mr. Scratch now brings you Scratch Fix Pro, a DIY scratch and chip repair kit.</p>
            <p> We have developed a kit that contains everything you need to repair scratches and
              chips on your car. We have sourced only the best products and have combined them
              into the Scratch Fix Pro – Paint touch up kit. Scratch Fix Pro offers thousands of
              custom tailor-made paint formulations to ensure perfect colour code match. Each
              paint kit is made one at a time per the factory colour formulation. Your car’s
              colour code can be found on your vehicle. Our team uses this code to formulate a
              perfect colour match paint for our car.
            </p>
          </div>

          <div className="col-md-6"><img src="/img/about1.jpg" alt="" style={{ width: "100%" }} /></div>

        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <h2>Customer Reviews</h2>
          </div>
        </div>

        <div className="row reviews">
          <div className="col-md-4">
            <img src="/img/Customer-1.png" alt="" />
            <h4>Gerhard Marais</h4>
            <p>“My Kit was damaged by the courier. I received the new package today in perfect condition. Thank you for the excellent customer service.”</p>
          </div>

          <div className="col-md-4">
            <img src="/img/Customer-2.png" alt="" />
            <h4>Nirvashen Archary</h4>
            <p>“The colour match is excellent, perfect match to my car, overall it’s a good touch up kit, with all the tools needed to colour minor scratches and make bigger scratches less noticeable.”</p>
          </div>

          <div className="col-md-4">
            <img src="/img/Customer-4.png" alt="" />
            <h4>Jetesh Naidu</h4>
            <p>"Needed some help finding my colour code. The Customer support team was fast and very helpful. The Kit works great and the colour is prefect! Thank you for the speedy service.”</p>
          </div>
        </div>

      </div>
    )
  }
}
