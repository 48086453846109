import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { Login } from "./login"

import { PaintManager } from "./paintmanager"

import { api } from "../../../api"
import { Dashboard } from "./dashboard";
import { ProductManager } from "./products";

import { Button, Typography, Box } from '@mui/material'
import { CategoriesManager } from './categories/categories_manager'
interface Props {
  match: any
}
interface State {
  admin: boolean;
  loading: boolean;
}



export class Admin extends Component<Props, State> {
  state = {
    admin: false,
    loading: true
  };

  componentDidMount() {

    api.getaccount();

    api.on("accountUpdated", (account) => {
      //this.setState({ account })
      if (account.admin === true) {
        this.setState({ admin: account.admin, loading: false })
      } else {
        this.setState({ loading: false })
      }
    })
  }

  render() {

    if (this.state.loading) { return <div style={{ paddingTop: 100 }}>LOADING</div> }
    if (!this.state.admin) { return (<Login />) }

    // const { match } = this.props // coming from React Router.

    // console.log(match.path) // /topics/:topicId/:subId
    // console.log(match.url) // /topics/react-router/url-parameters

    return (
      <Box sx={{ m: 2 }}>
        <Typography variant="h4">ADMIN</Typography>


        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
          <Button component={Link} to="/admin" >Dashboard</Button>
          <Button component={Link} to="/admin/paintadd" >Paint</Button>
          <Button component={Link} to="/admin/products" >Products</Button>
          <Button component={Link} to="/admin/categories" >Categories</Button>
        </Box>

        <Router >
          <Route exact path="/admin" component={Dashboard} />
          <Route exact path="/admin/paintadd" component={PaintManager} />
          <Route exact path="/admin/products" component={ProductManager} />
          <Route exact path="/admin/categories" component={CategoriesManager} />
        </Router>

      </Box>
    )
  }
}
