import { Component } from 'react';
import { SFPCartItem } from '../shared/interfaces';
import { api } from '../../api';
import { Account } from './account'
import { Box, Button, Container, Divider, IconButton, Typography } from '@mui/material';

// https://mui.com/x/react-data-grid/#commercial-version
import {
  useGridApiRef,
  DataGridPro,
  GridApi,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridEvents,
  GridRowId,
  GridRowModel,
  LicenseInfo,
  GridSortModel,
  GridSortItem,
  GridFilterModel,
  GridLinkOperator
} from '@mui/x-data-grid-pro';
import { ArrowRightTwoTone, ChevronRightRounded, ChevronRightTwoTone, CloseTwoTone } from '@mui/icons-material';
import { fontWeight } from '@mui/system';

// rouan's key
LicenseInfo.setLicenseKey('11dfa392be05d10d58887edf4f20e775T1JERVI6NDE2MjgsRVhQSVJZPTE2ODEzMzgwODU1NTIsS0VZVkVSU0lPTj0x');

interface State {
  cartTotal: number
  accountFormValid: boolean
}

export class Cart extends Component<{}, State> {
  state = {
    cartTotal: 0,
    accountFormValid: false
  };

  componentDidMount() {
    // this.updateAccount();
  }

  remove = (item: SFPCartItem) => {

    fetch('/api/user/cartremoveid', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item)
    }).then(response => response.json()).then(result => {
      // this.updateAccount();
      if (result) {
        api.getaccount();
      }
    }).catch(err => console.error(err.toString()));


  }

  shipping = () => {
    // const shippingCost = 100.0
    return (<div className='row cartItemLine' style={{ color: 'black' }}>
      <div className='col ' style={{ paddingLeft: 15, textAlign: 'right' }}>+ 7 DAY SHIPPING (SOUTH AFRICA)</div>
      <div className='col ' style={{ flex: '0 0 150px', textAlign: 'right' }}> R {api.account.shipping} </div>
      <div className='col' style={{ flex: '0 0 50px' }}></div>
    </div>)
  }

  total = () => {
    return (<div className='row cartItemLine ' style={{ fontSize: 30, color: 'black' }}>
      <div className='col ' style={{ paddingLeft: 15, textAlign: 'right' }}>TOTAL</div>
      <div className='col ' style={{ flex: '0 0 150px', textAlign: 'right' }}> R {api.account.cartTotal} </div>
      <div className='col' style={{ flex: '0 0 50px' }}></div>
    </div>)
  }

  // TODO NEW GRID
  cart = () => {
    console.log(JSON.stringify(api.account.cart, null, 2));
    return <Box>

      <DataGridPro
        autoHeight
        columns={[
          {
            field: 'picture',
            headerName: '',
            width: 55,
            renderCell: (props) => {

              if (props.row.product) {
                let image = '/placeholder.jpg';
                if (props.row.product?.images?.length > 0) image = props.row.product.images[0];
                return <img src={image} alt="img"
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: 5,
                    padding: 0,
                    margin: 0
                  }} />
              }
              if (props.row.paint) {
                return <div
                  style={{
                    background: props.row.paint.color,
                    width: 36,
                    height: 36,
                    borderRadius: 5,
                    padding: 0,
                    margin: 0
                  }} />
              }
              return <></>
            }
          },
          {
            field: 'cartItemUuid',
            headerName: 'Description',
            flex: 1,
            renderCell: (props) => {
              if (props.row.paint) {
                return <Box>
                  <Typography variant="h6" sx={{
                    textTransform: 'capitalize',
                    fontSize: "0.9em", m: 0, p: 0, lineHeight: 1,
                    fontWeight: 600
                  }}>
                    {props.row.package} paint kit</Typography>
                  <Typography sx={{
                    textTransform: 'capitalize',
                    fontSize: "0.75em", m: 0, p: 0, lineHeight: 1
                  }}>
                    {props.row.car.brandName}&nbsp;
                    {props.row.car.modelName}&nbsp;
                    - {props.row.paint.desc}&nbsp;
                    - {props.row.paint.code}
                  </Typography>
                </Box>
              }

              if (props.row.product) {
                return <Typography variant="h6" sx={{
                  textTransform: 'capitalize',
                  fontSize: "0.9em", m: 0, p: 0, lineHeight: 1,
                  fontWeight: 600
                }}>{props.row.product.name}</Typography>
              }

              return <Typography
                variant={props.row.cartItemUuid === 'TOTAL' ? "h6" : undefined}
                sx={{
                  width: '100%',
                  textAlign: 'right'
                }}
                fontWeight={600}>{props.row.cartItemUuid}</Typography>

            }
          },
          {
            field: 'price',
            headerName: 'Price',
            align: 'right',
            renderCell: (props) => <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}>
              <Typography
                variant={props.row.cartItemUuid === 'TOTAL' ? "h6" : undefined}
                fontWeight={800}>R</Typography>
              <Box sx={{ flex: 1 }} />
              <Typography
                variant={props.row.cartItemUuid === 'TOTAL' ? "h6" : undefined}
                fontWeight={800}>{props.value}</Typography>
            </Box>
          }, {
            field: 'placeholder',
            headerName: ' ',
            width: 30,
            renderCell: (props) => {
              if (!props.row.paint && !props.row.product) return <></>
              return <IconButton
                size="small"
                color="error"
                onClick={() => { this.remove(props.row); }}
                aria-label="delete">
                <CloseTwoTone />
              </IconButton>
            }
          }
        ]}
        rows={api.account.cart.concat([{
          cartItemUuid: '+7 DAY SHIPPING',
          price: api.account.shipping,
        }, {
          cartItemUuid: 'TOTAL',
          price: api.account.cartTotal,
        }] as SFPCartItem[])}
        hideFooter
        headerHeight={0}
        getRowId={(row: any) => row.cartItemUuid}
      />
    </Box>
  }

  // TODO DEPRECATE
  // cartOld = () => {
  //   if (api.account) {
  //     if (api.account.cart) {
  //       return (<div>

  //         {api.account.cart.map((item, i) => {
  //           let classes = 'row cartItem';
  //           if (i === 0) { classes += ' cartItemFirst' }
  //           // if (i == api.account.cart.length - 1) { classes += " cartItemLast" }

  //           let typeFound = false;

  //           if (item.paint) {
  //             typeFound = true;
  //             return (<div className={classes} key={i} >
  //               <div className='col' style={{ flex: '0 0 35px', padding: 0 }}>
  //                 <div className='swatch' style={{ background: item.paint.color }} />
  //               </div>

  //               <div className='col cartItemBorderRight' style={{ paddingLeft: 15 }}>
  //                 <div style={{ paddingLeft: 10 }}>
  //                   <span style={{ textTransform: 'capitalize' }}>{item.package} Paint Kit</span><br />
  //                   <span style={{ textTransform: 'capitalize', color: '#777' }}>
  //                     {item.car.brandName}&nbsp;
  //                     {item.car.modelName}&nbsp;
  //                     - {item.paint.desc}&nbsp;
  //                     - {item.paint.code}</span>
  //                 </div>
  //               </div>

  //               <div className='col cartItemPrice' style={{
  //                 flex: 0,
  //                 padding: '10px 15px 0 10px',
  //                 textAlign: 'right',
  //                 whiteSpace: 'nowrap'
  //               }}>
  //                 R {item.price}
  //               </div>
  //               <div className='col cartItemRemove' onClick={() => { this.remove(item); }}
  //                 title='remove'
  //                 style={{ flex: '0 0 50px' }}>
  //                 <i className='fas fa-times'></i>
  //               </div>
  //             </div>)
  //           }

  //           if (item.product) {
  //             let image = '/placeholder.jpg';

  //             if (item.product.images) {
  //               if (item.product.images.length > 0) {
  //                 image = item.product.images[0];
  //               }
  //             }
  //             typeFound = true;
  //             return (<div className={classes} key={i} >

  //               <div className='col' style={{ flex: '0 0 35px', padding: 0 }}>
  //                 <div className='swatch' style={{}}>
  //                   <img src={image} alt="img" style={{ width: '100%' }} />
  //                 </div>
  //               </div>

  //               <div className='col cartItemBorderRight' style={{ paddingLeft: 15 }}>
  //                 <div style={{ paddingLeft: 10 }}>{item.product.name}</div>
  //               </div>

  //               <div className='col cartItemPrice' style={{ flex: '0 0 250px', padding: '10px 15px 0 10px', textAlign: 'right' }}>
  //                 R {parseFloat(item.product.price)}
  //               </div>
  //               <div className='col cartItemRemove' onClick={() => { this.remove(item); }} title='remove' style={{ flex: '0 0 50px' }}>
  //                 <i className='fas fa-times'></i>
  //               </div>
  //             </div>)
  //           }

  //           if (typeFound === false) {
  //             return <div className={classes} key={i} >
  //               <div className='col' style={{ flex: '0 0 35px', padding: 0 }}>
  //                 <div className='swatch' style={{ background: '#ffffff' }} />
  //               </div>
  //               <div className='col cartItemBorderRight' style={{ flex: '0 0 120px', paddingLeft: 15, textTransform: 'uppercase', color: '#777' }}>
  //                 UNKNOWN<br />
  //                 ITEM
  //               </div>
  //               <div className='col cartItemBorderRight' style={{ paddingLeft: 15 }}>
  //                 <pre>{JSON.stringify(item, null, 2)}</pre>
  //               </div>
  //               <div className='col cartItemPrice' style={{ flex: '0 0 250px', padding: '10px 15px 0 10px', textAlign: 'right' }}>
  //                 R {item.price.toFixed(2)}
  //               </div>
  //               <div className='col cartItemRemove' onClick={() => { this.remove(item); }} title='remove' style={{ flex: '0 0 50px' }}>
  //                 <i className='fas fa-times'></i>
  //               </div>
  //             </div>
  //           }

  //           return null;
  //         })}

  //         {this.shipping()}
  //         {this.total()}
  //       </div>)
  //     }
  //   }

  //   return (<div>cart empty</div>)
  // }

  payment = (enabled: boolean) => {

    if (api.account.cartTotal === 0) enabled = false;

    if (!enabled) {
      return (<div className='row padtop'>
        <div className='col-12'  >
          <button id='payment' className='buttonDisabled' style={{ float: 'right' }} disabled={true} >PAYMENT <span>
            <i className='fas fa-arrow-right' ></i></span></button><br />
        </div>
      </div>)
    }
    return (
      <div className='row padtop'>
        <div className='col-12' >


          <form action='https://www.payfast.co.za/eng/process' method='POST'>
            <input type='hidden' name='merchant_id' value='12944071' />
            <input type='hidden' name='merchant_key' value='xvlw5hqgtqknh' />


            {/* <form action='https://sandbox.payfast.co.za/eng/process' method='POST'>
            <input type='hidden' name='merchant_id' value='10000100' />
            <input type='hidden' name='merchant_key' value='46f0cd694581a' /> */}

            <input type='hidden' id='paymentFirstName' name='name_first' value={api.account.info.firstName} />
            <input type='hidden' id='paymentLastName' name='name_last' value={api.account.info.lastName} />
            <input type='hidden' id='paymentEmail' name='email_address' value={api.account.info.email} />
            <input type='hidden' id='paymentTelephone' name='cell_number' value={api.account.info.telephone} />
            <input type='hidden' id='paymentConfirmAmount' name='amount' value={api.account.cartTotal.toFixed(2)} />

            <input type='hidden' name='item_name' value='ScratchFixPro' />
            <input type='hidden' name='item_description' value='PaintKit Order' />

            <input type='hidden' id='paymentUuid' name='custom_str1' value={api.account.uuid} />


            <input type='hidden' name='return_url' value='https://scratchfixpro.co.za/payment/success' />
            <input type='hidden' name='cancel_url' value='https://scratchfixpro.co.za/' />
            <input type='hidden' name='notify_url' value='https://scratchfixpro.co.za/api/payment/notify' />
            {/* <Button
              component="button"
              id='payment' className='blueButton'
              size="large"
              variant="contained"
              endIcon={<ChevronRightRounded />}
            >PAYMENT</Button> */}
            <button id='payment' className='blueButton' style={{ float: 'right' }} >PAYMENT <span>
              <i className='fas fa-arrow-right' ></i></span></button>
          </form>
        </div>
      </div>)
  }

  accountValidation = (accountFormValid: boolean) => {
    this.setState({ accountFormValid })
  }

  render() {
    // if (!api.account) { return null; }

    return (
      <Box sx={{ p: 0 }}>
        <Container sx={{ p: 0 }}>
          <Box sx={{ p: 1, pt: 2 }}>
            <Typography variant="h5" fontWeight={800} color="secondary">CART</Typography>
            <Typography fontWeight={600} color="primary">YOUR ORDER:</Typography>
          </Box>

          {this.cart()}

          {/* {this.cartOld()} */}

          <Box sx={{ p: 1, pt: 2 }}>
            <Typography variant="h5" fontWeight={800} color="secondary">ACCOUNT</Typography>
            <Typography fontWeight={600} color="primary">DELIVERY TO:</Typography>
          </Box>

          <Account validation={this.accountValidation} />

          <Box sx={{ mb: 2 }}>{this.payment(this.state.accountFormValid)}</Box>
          <img src="/payfast.jpg" alt="payfast accepts" />
        </Container>
      </Box>

    )
  }

}

