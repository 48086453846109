import { ICategory } from "../../../../../../shared/interfaces";

/** returns the category full breadcrumb string */
export function parentCategoriesBreadcrumb(categories: ICategory[] | undefined, category_id: string | undefined): string {

    if (!category_id) return '';
    if (!categories) return '...';
    const found = categories.filter((cat) => cat._id === category_id)
    if (found.length === 0) return 'notfound ->';

    if (!found[0]) return '';
    let category = found[0];
    if (category.parent_category_id) return `${parentCategoriesBreadcrumb(categories, category.parent_category_id)}/${category.name}`;
    if (category) return `/${category.name}`

    return '';
}