import { Component } from 'react';

import { PaintBrowser } from './paintbrowser';
import { PaintProduct } from './paintproduct';
import { SFPPaint } from '../../shared/interfaces';
import { PaintFind } from './paintfind';
import { PaintEntry, PaintFull } from '../../shared/interfaces/dbtypes';

interface Props {
  updateCart: (cb?: any) => void
}

interface State {
  paints: PaintEntry[]
  car: any
  /** currently viewed paint product */
  product: any,
  codeFilter: string;
}

export class Paint extends Component<Props, State> {
  state: State = {
    product: undefined,
    paints: [],
    car: undefined,
    codeFilter: ""
  };

  componentDidMount = () => {
    // this.setState({
    //   "product": {
    //     "car": {
    //       "brandName": "BEDFORD",
    //       "modelName": "ALL"
    //     },
    //     "paint": {
    //       "href": "https://colorndrive.com/en/touch-up-paint/bedford-all-bright-green-starfire-met-9gn,6896/details",
    //       "color": "linear-gradient(to right, rgb(146, 169, 98), rgb(51, 79, 36))",
    //       "code": "9GN",
    //       "desc": "BRIGHT GREEN STARFIRE MET"
    //     }
    //   }
    // })
  };

  onCarSelect = (car: any) => {
    this.setState({ car });
    this.getCarPaint(car);
  };

  getCarPaint = (car: any) => {
    fetch('/api/paints', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(car)
    })
      .then(response => response.json())
      .then((data: PaintFull) => {
        data.paints = data.paints.filter((p) => (p.color !== ""))
        this.setState({ car, paints: data.paints, product: undefined });
      })
      .catch(err => console.error(err.toString()));
  };

  /////

  onPaintSelect = (paint: SFPPaint) => {
    return () => {
      let opt = { product: { car: this.state.car, paint } };
      console.log(opt)
      this.setState(opt);
    };
  };

  ////

  render() {

    return (
      <div style={{ position: 'relative', top: -40 }} className=''>
        <PaintFind
          clearPaintViewer={() => {
            this.setState({ car: undefined, paints: [], product: undefined })
          }}
          onCode={codeFilter => {
            this.setState({ codeFilter })
          }}
          selectCar={this.onCarSelect} />
        <PaintProduct
          onClose={() => { this.setState({ product: undefined }) }}
          product={this.state.product}
          updateCart={this.props.updateCart} />
        <PaintBrowser
          codeFilter={this.state.codeFilter}
          paints={this.state.paints}
          selectPaint={this.onPaintSelect}
        />
      </div>
    );
  }
}
