import React from 'react';

import {
    TextField,
    Paper,
    Typography,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
} from '@mui/material';
import { ICategory } from '../../../../../../shared/interfaces';
import { api } from '../../../../api';
import { parentCategoriesBreadcrumb } from './categories_utils'

interface Props {
    _id: string,
    onDone: () => void
}

interface State {
    category: ICategory
    categories: ICategory[]
}

export class CategoryEdit extends React.Component<Props, State> {
    state: State = {
        category: {
            _id: '',
            name: '',
            slug: ''
        },
        categories: []
    }

    clearCategoryState = () => {
        this.setState({
            category: {
                _id: '',
                name: '',
                slug: '',
                parent_category_id: undefined
            }
        })
    }

    componentDidMount = () => {
        this.getCategory();
        this.getCategories();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps._id !== this.props._id) {
            this.getCategory();
        }
    }

    getCategory = async () => {
        if (this.props._id) {
            this.clearCategoryState();
            const response = await api.getCategories({ _id: this.props._id });

            if (response.data && response.data[0]) this.setState({ category: response.data[0] })
        }

    }

    getCategories = async () => {
        const response = await api.getCategories();
        if (response.data) this.setState({ categories: response.data });
    }

    updateCategory = async () => {
        const result = await api.categoryUpdate(this.state.category);
        this.props.onDone();
    }

    render() {
        return <Box style={{}}>
            <Paper sx={{ p: 3, background: 'rgba(0,0,100,0.025)', display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant="h5"

                >EDIT CATEGORY DETAILS </Typography>
                <Typography variant="caption" sx={{ mb: 4 }}>
                    Edit the details of the category</Typography>


                <TextField
                    sx={{ mt: 2, mb: 2 }}
                    label="Name:"
                    hiddenLabel
                    variant='outlined'
                    value={this.state.category.name}
                    onChange={(event: React.ChangeEvent<{ value: string }>) => {
                        const category = this.state.category;
                        category.name = event.target.value;
                        this.setState({ category })
                    }} />

                <Typography sx={{ mt: 2 }}>Parent:</Typography>
                <Select
                    label="Category:"
                    value={this.state.category?.parent_category_id || ''}
                    onChange={(event) => {
                        const category = this.state.category;
                        category.parent_category_id = event.target.value;
                        this.setState({ category })
                    }}
                    input={<OutlinedInput label="Parent Category" />}
                >
                    <MenuItem key={'none'} value={''}>none</MenuItem>

                    {/* cant select itself */}
                    {this.state.categories.filter(c => c._id != this.state.category._id).map((category, index) => (
                        <MenuItem
                            key={category._id || index}
                            value={category._id}
                        >
                            {parentCategoriesBreadcrumb(this.state.categories, category._id)}
                        </MenuItem>
                    ))}
                </Select>




                <Button variant="contained" onClick={() => {
                    this.updateCategory();
                }}>SAVE</Button>

            </Paper>
        </Box>
    }
}