import React, { Component } from 'react';
import { Product } from '../../../shared/interfaces/product';
import { api } from '../../../../api';
import { UploadArea } from '../../uploadarea/uploadarea';
import { ProductComponent } from '../../product';
import {
    TextField,
    Paper,
    Typography,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    FormControlLabel,
    IconButton
} from '@mui/material';
import { ICategory } from '../../../../../../shared/interfaces';
import { parentCategoriesBreadcrumb } from '../categories/categories_utils';
import { ImageUpload } from '../../imageupload/imageUpload';
import { DeleteTwoTone } from '@mui/icons-material';


// backgroundColor: product._id === this.state.editProductId ? (theme) => alpha(theme.palette.primary.main, 0.1) : undefined
interface Props {
    /** the requested _id to edit */
    _id: string,
    onDone: () => void
}
interface State {
    /** the currently editing _id */
    _id: string
    /** the current product data */
    product: Product
    categories: ICategory[]
}

export class ProductEdit extends Component<Props, State> {
    state: State = {
        _id: this.props._id,
        product: {
            _id: '',
            addedtime: new Date(),
            price: '',
            name: '',
            description: '',
            images: [],
            sku: '',
            stockQuantity: 0
        },
        categories: []
    }

    clearProduct = async () => {
        await this.setState({
            product: {
                _id: '',
                addedtime: new Date(),
                price: '',
                name: '',
                brand: undefined,
                category_id: '',
                description: '',
                images: [],
                sku: '',
                stockQuantity: 0
            }
        })
    }

    componentDidMount() {
        this.getProduct();
        this.getCategories();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps._id !== this.props._id) {
            this.getProduct();
        }
    }

    getProduct = async () => {
        if (this.props._id) {
            await this.clearProduct();
            api.getProduct(this.props._id, (e, product) => {
                if (e) { console.log(e); }
                console.log('getProduct', product)
                this.setState({ product });
            })
        };
    }

    getCategories = async () => {
        const result = await api.getCategories();
        if (result?.data) this.setState({ categories: result.data })
    }

    loadImages = () => {
        if (this.props._id) {
            api.getProduct(this.props._id, (e, productServer) => {
                if (e) { console.log(e); }
                const product = this.state.product;
                product.images = productServer.images;
                this.setState({ product });
            })
        };
    }

    updateProduct = async () => {
        await api.postProductUpdate(this.state.product);
        this.props.onDone();
    }

    // updateProduct2 = async () => {
    //     const result = await api.postProductUpdate(this.state.product);
    //     this.props.onDone();
    // }

    render() {
        return (
            <Box >
                <Box className='segment' style={{}}>
                    <Paper sx={{ p: 3, background: 'rgba(0,0,100,0.025)', display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="h5"

                        >EDIT PRODUCT DETAILS </Typography>
                        <Typography variant="caption" sx={{ mb: 4 }}>
                            Edit the details of the product and add images.</Typography>

                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            label="Brand:"
                            value={this.state.product.brand || ''}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                const product = this.state.product;
                                product.brand = event.target.value;
                                this.setState({ product })
                            }} />

                        <TextField
                            sx={{ mt: 2, mb: 2 }}
                            label="Name:"
                            hiddenLabel
                            variant='outlined'
                            value={this.state.product.name}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                const product = this.state.product;
                                product.name = event.target.value;
                                this.setState({ product })
                            }} />

                        <Typography sx={{ mt: 2 }}>Category:</Typography>
                        <Select
                            label="Category:"
                            value={this.state.product.category_id || ''}
                            onChange={(event) => {
                                const product = this.state.product;
                                product.category_id = event.target.value;
                                this.setState({ product });
                            }}
                            input={<OutlinedInput label="Category" />}
                        >
                            {this.state.categories.map((category, index) => (
                                <MenuItem
                                    key={category._id || index}
                                    value={category._id}
                                >
                                    {parentCategoriesBreadcrumb(this.state.categories, category._id)}
                                </MenuItem>
                            ))}
                        </Select>

                        <TextField
                            label="Description:"
                            multiline
                            name='description'
                            sx={{ width: '100%', mt: 2 }}
                            rows={10}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                const product = this.state.product;
                                product.description = event.target.value;
                                this.setState({ product })
                            }}
                            value={this.state.product.description} />


                        <TextField
                            label="Price:"
                            type='text'
                            sx={{ mt: 2 }}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                const product = this.state.product;
                                product.price = event.target.value;
                                this.setState({ product })
                            }}
                            value={this.state.product.price}
                        />

                        <TextField
                            label="SKU code:"
                            type='text'
                            sx={{ mt: 2 }}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                const product = this.state.product;
                                product.sku = event.target.value;
                                this.setState({ product })
                            }}
                            value={this.state.product.sku}
                        />

                        <TextField
                            label="Stock Quantity"
                            type='number'
                            sx={{ mt: 2 }}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                const product = this.state.product;
                                product.stockQuantity = parseInt(event.target.value);
                                this.setState({ product })
                            }}
                            value={this.state.product.stockQuantity || 0}
                        />



                        <Box sx={{ border: 1, borderColor: 'divider', p: 2, mt: 2, mb: 2, borderRadius: 1 }}>
                            <Typography sx={{ mb: 2 }}>Images:</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {this.state.product.images.map((img, imgkey) => <Box key={imgkey} sx={{
                                    position: 'relative',
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    overflow: 'hidden',
                                    p: "1px"
                                }}>
                                    <img src={img} alt={`productimg_${imgkey}`} style={{
                                        height: 175
                                    }} />
                                    <IconButton
                                        size="small"
                                        sx={{
                                            background: 'rgba(125,125,125,0.5)',
                                            color: 'white',
                                            opacity: 0.25,
                                            position: 'absolute', right: 5, top: 5
                                        }}><DeleteTwoTone onClick={() => {
                                            console.log(`delete image number ${imgkey}`)
                                            const product = this.state.product;

                                            product.images.splice(imgkey, 1);
                                            this.setState({ product });
                                        }} /></IconButton>
                                </Box>)}
                            </Box>


                            <ImageUpload
                                compressOptions={{
                                    size: 2,
                                    quality: 0.5,
                                    maxWidth: 300,
                                    maxHeight: 300,
                                    resize: true
                                }}
                                onChange={(newimages) => {
                                    const product = this.state.product;
                                    if (!product.images) product.images = [];
                                    product.images = product.images.concat(newimages);
                                    this.setState({ product });
                                }}
                            >
                                <Typography sx={{
                                    mt: 2,
                                    backgroundColor: 'primary.main', color: 'white',
                                    p: 1,
                                    borderRadius: 1,
                                    pl: 2,
                                    pr: 2
                                }}>Upload Images</Typography>
                            </ImageUpload>
                        </Box>


                        {/* <Box sx={{ border: 1, borderColor: 'divider', mt: 2, mb: 2 }}>
                            <UploadArea _id={this.props._id} onUpload={() => {
                                console.log('UPLOADED')
                                this.loadImages();
                            }} />
                        </Box> */}


                        <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': 'controlled' }}
                            checked={this.state.product.active === true}
                            value={this.state.product.active === true}
                            onChange={() => {
                                const product = this.state.product;
                                product.active = !product.active;
                                this.setState({ product });
                            }}
                        />} label="Activate product for purchase" />


                        <Button variant="contained" onClick={() => {
                            this.updateProduct();
                        }}>SAVE</Button>

                        {/* <pre>{JSON.stringify(this.state.product, null, 2)}</pre> */}
                    </Paper>
                </Box>
            </Box>
        )
    }
}
