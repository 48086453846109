import { Component } from "react";

export class FAQPage extends Component {
  state = {};

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="guide">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2 style={{ marginBottom: 20 }}>Frequently Asked Questions</h2>
                <h1>FAQ</h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div>
                <h2 style={{ marginBottom: 20 }}>General Questions</h2>

                <h5>1.	Do you have a store or a applications center?</h5>
                <p>Although we have an office and a distribution center in South Africa. We are a purely online based business. If you need help with applications out support team <a href="mailto:info@scratchfixpro.co.za">info@scratchfixpro.co.za</a>. is always willing to guide you through the application process.</p>

                <h5>2.	Can I pick up my order at your distribution center?</h5>
                <p>No. We ship directly to your door within 5-10 working day.</p>

                <h5>3.	Can I make an eft payment?</h5>
                <p>We use PayFast as a payment platform. Payfast allows you to make EFT payments, as well as 7 other payment methods: Credit Cards, Debit Cards, Cheque Cards, Instant EFT for all major south African banks, Masterpass, SnapScan, SCode, Zapper and Mobicred.</p>
              </div>
            </div>
          </div>

          <hr style={{ marginTop: 25, marginBottom: 50 }} />

          <div className="row">
            <div className="col-12">
              <div>
                <h2 style={{ marginBottom: 20 }}>Our Kits</h2>

                <h5>1.	What is the shelf life of the kits?</h5>
                <p>The shelf life of our products can be up to 12 months. Assuming the product is stored in a cool, dark and dry place.</p>

                <h5>2.	Do I need car repair experience to use the kit?</h5>
                <p>No, with every kit you will receive an instructional leaflet that is easy to follow.</p>

                <h5>3.	Is this a permanent repair? </h5>
                <p>Yes, after the paint has cured for 3 days our paint has the same resistance as your car’s regular paint. We only use the highest quality automotive paint.</p>

                <h5>4.	Can I wash my car after using the kit?</h5>
                <p>We recommend that you not wash your car for 3 days after applying the paint and that you take care when washing the car. Ensure that you use non-abrasive materials when washing your car.</p>

                <h5>5.	How do you mix my car’s exact colour? </h5>
                <p>We use a professional mixing bank to mix all our kits. We use your paint code to ensure the colour is mixed to factory specifications. If you receive the incorrect colour please contact our <a href="mailto:info@scratchfixpro.co.za">support team</a> so they can help you with the issue.</p>

                <h5>6.	Do I need clear coats or primers in order to fix a scratch? </h5>
                <p>This is a DIY kit and the kit contains everything you may need for minor scratch and stone chip repair at home.</p>

                <h5>7.	Can I repair rust with the Kit?</h5>
                <p>No, it’s important to fix the rust damage before applying paint to your car, otherwise the rust will continue to spread under a coat of paint. We Suggest you do rust repair at a professional workshop or panel beater.</p>

                <h5>8.	Can I fix deep scratches? </h5>
                <p>In some cases, you can by applying multiple coats of paint in order to fill the scratch. Our product is designed to fix minor scratches and chips and will over all inprove to look of scratches on your car. Use your own discretion to decide if your car needs a professional panel beater or our DIY kit.</p>
              </div>
            </div>
          </div>

          <hr style={{ marginTop: 25, marginBottom: 50 }} />

          <div className="row">
            <div className="col-12">
              <div>


                <h2 style={{ marginBottom: 20 }}>Colour Codes</h2>

                <div style={{ paddingTop: 10, paddingBottom: 30 }}>
                  <img src="/img/faq/paintCodeLocator.jpg" alt="paint code locator map" style={{ width: '50%' }} />
                  <img src="/img/faq/paintCodeExamples.jpg" alt="paint code examples" style={{ width: '50%' }} />
                </div>

                <h5>1.	I don’t know what my colour code is for my vehicle? </h5>
                <p>Your colour code is usually located on an ID sticker on your car. You can use the colour code locator to find your colour code. If you still can’t find your colour code you can contact your local dealership with your Vin Number to find out the exact colour code for your car. Alternatively, you can also contact our <a href="mailto:info@scratchfixpro.co.za">support team</a> for help. </p>

                <h5>2.	What does my colour code look like?</h5>
                <p>Please refer to our colour code locator for examples of colour codes.</p>

                <h5>3.	I can not find my Colour Code on the database?</h5>
                <p>If you have your colour code bur you can not find it on our site please contact support so they can load your colour onto our site. </p>

                <h5>4.	What is if I order the wrong colour? </h5>
                <p>It is your responsibility to check your colour code. If you need help finding your colour code or you are not sure please contact our <a href="mailto:info@scratchfixpro.co.za">support team</a> for help. </p>


              </div>
            </div>
          </div>

          <hr style={{ marginTop: 25, marginBottom: 50 }} />

          <div className="row">
            <div className="col-12">
              <div>


                <h2 style={{ marginBottom: 20 }}>Shipping</h2>

                <h5>1.	How long does it take to ship my order? </h5>
                <p>You will receive your order within 3 to 10 working days after placing your order.
                  You might receive your parcel faster.</p>

                <h5>2.	Can I track my order?</h5>
                <p>Yes. If you would like to track your order all you have to do is email a request for your tracing number to our <a href="mailto:info@scratchfixpro.co.za">support team</a>. </p>

                <h5>3.	What if my kit gets damaged while shipping? </h5>
                <p>Accidents do happen when working with shipping companies. So, if you receive a damaged kit please contact our <a href="mailto:info@scratchfixpro.co.za">support team</a> and they will assist you. </p>

                <h5>4.	Do you ship anywhere in south Africa? </h5>
                <p>Yes we do. Please ensure that your shipping address is accurate.</p>

                <h5>5.	Do you ship internationally?</h5>
                <p>No, for now we only ship within South Africa. </p>

              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}
