import { Component } from "react";
import { Orders } from "./orders";



export class Dashboard extends Component {
    state = {};

    render() {
        return (
            <div>
                <Orders />
            </div>

        )
    }
}
